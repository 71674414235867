import { Checkbox } from "flowbite-react";
import { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonCreateCustom } from "../../../../../components/ButtonCreateCustom";
import { ButtonEditCustom } from "../../../../../components/ButtonEditCustom";
import { ButtonDeleteCustom } from "../../../../../components/ButtonDeleteCustom";
import ButtonSearchCuston from "../../../../../components/ButtonSearchCustom";
import { pessoaModel } from "../../../../../resource/request";
import api_app from "../../../../../apis/api_app";
import { store } from "../../../../../redux/store";
import InputSearchCuston from "../../../../../components/InputSearchCustom";


interface State {
    pessoa: pessoaModel;
    searchTerm: string;
}

export class ListTelefone extends Component<any> {

    state: State = {
        pessoa: store.getState().user?.user,
        searchTerm: '',
    };

    componentDidMount() {

    }

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchTerm: event.target.value });
    };

    getFilteredTelefones = () => {
        const { pessoa, searchTerm } = this.state;
        if (!pessoa?.pessoa_telefone) return [];

        return pessoa.pessoa_telefone.filter(tele =>
            tele.telefone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            tele.observacao?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    render() {
        const filteredTelefones = this.getFilteredTelefones();
        return <>
            <div className="text-lg font-semibold text-gray-900 dark:text-white">
                <TittleCustom name="Dados De Telefone" />
            </div>
            <div
                className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <ButtonCreateCustom link="/adicionar-telefone" name="Adicionar Telefone" />
                <InputSearchCuston
                    value={this.state.searchTerm}
                    onChange={this.handleSearchChange}
                    name="searchTerm"
                />
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Telefone
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Descrição
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Principal
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opções
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTelefones.map((tele) => (

                            <tr key={tele.id}
                                className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${tele.flg_visivel === 'S' ? '' : 'hidden'}`}
                            >
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white ">
                                    {tele.telefone}
                                </td>
                                <td className="px-6 py-4">
                                    {tele.observacao}
                                </td>
                                <td className="px-6 py-4">
                                    {tele.flg_principal === "S" ? 'Sim' : 'Não'}
                                </td>
                                <td className="px-6 py-4">
                                    <div className="flex items-center">
                                        <ButtonEditCustom link={`/alterar-telefone/${tele.id}`} />
                                        {tele.flg_principal !== "S" && (
                                            <ButtonDeleteCustom link={`/pessoa-telefone/${tele.id}`} />
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    }
}