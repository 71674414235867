// GroupCheck.js
import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {jwtDecode, JwtPayload} from "jwt-decode";
interface CustomJwtPayload extends JwtPayload {
    groups?: string[];
}

const GroupCheck = () => {
    const { keycloak } = useKeycloak();

    if (keycloak && keycloak.token) {
        const decodedToken = jwtDecode<CustomJwtPayload>(keycloak.token);
        console.log(decodedToken);

        if (decodedToken?.groups?.includes('/profissional')) {
            return <div>User belongs to the group!</div>;
        }
        return <div>User does not belong to the group.</div>;
    }

    return <div>Loading...</div>;
};




export default GroupCheck;
