import { link } from "fs";
import React, { useState } from "react";

export interface PropertiesCustom {
    name: string;
    label: string;
    value: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    linklToFile?: string;
}

const FileInputCustom = (props: PropertiesCustom) => {
    const [selectedFile, setSelectedFile] = useState<File | null>();
    const [fileURL, setFileURL] = useState<string | null>(null);

    // Função para lidar com a seleção de arquivo
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
            setFileURL(URL.createObjectURL(file)); // Criar URL para visualização
            if (props.onChange) {
                props.onChange(event);
            }
        }
    };



    // Função para remover o arquivo selecionado
    const removeSelectedFile = () => {
        setSelectedFile(null);
        setFileURL(null); // Remover URL para visualização
    };

    // Função para visualizar o arquivo selecionado
    const viewSelectedFile = () => {
        if (fileURL) {
            window.open(fileURL, "_blank");
        }
    };

    // Função para visualizar o arquivo selecionado
    const viewSelectedFileExis = () => {
        if (props.linklToFile) {
            window.open(props.linklToFile, "_blank");
        }
    };

    return (
        <div className='mb-4'>
            <div className="flex items-center">
                <label
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    htmlFor={props.name}>
                    {props.label}
                </label>
                {selectedFile && (
                    <button
                        type="button"
                        onClick={viewSelectedFile}
                        className="ml-2 text-blue-900 dark:text-blue-400 focus:outline-none"
                        title="Visualizar Documento"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-5 mb-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
                        </svg>
                    </button>
                )}

                {(props.linklToFile && !selectedFile) && (
                    <button
                        type="button"
                        onClick={viewSelectedFileExis}
                        className="ml-2 text-blue-900 dark:text-blue-400 focus:outline-none"
                        title="Visualizar Documento"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
                        </svg>
                    </button>
                )}

            </div>
            <div className="relative w-full">
                <input
                    name={props.name}
                    value={props.value}
                    id={props.name}
                    type="file"
                    accept='image/jpeg, image/png, image/*,  application/pdf'
                    className="hidden"
                    onChange={handleFileChange}
                    required={props.required}
                />
                <label
                    htmlFor={props.name}
                    className="file-input flex items-center gap-2 w-full text-sm text-gray-800 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 py-2 px-4"
                >
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776" />
                        </svg>
                    </span>
                    <span>
                        {selectedFile ? (
                            <>
                                <span className="mr-2">{selectedFile.name}</span>
                                <button
                                    type="button"
                                    onClick={removeSelectedFile}
                                    className="text-red-500 dark:text-red-400 focus:outline-none"
                                >
                                    Remover
                                </button>
                            </>
                        ) : (
                            "Escolher Arquivo"
                        )}
                               

                    </span>
                </label>
            </div>
        </div>
    );
};

export default FileInputCustom;
