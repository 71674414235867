import React, { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import { pessoa_emailModel } from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import withRouter from "../../../../../helpers/withRouter";
import api_app from "../../../../../apis/api_app";
import { ToggleCustom } from "../../../../../components/ToggleCustom";
import SpinnerCustom from "../../../../../components/SpinnerCustom";

interface State {
    email: pessoa_emailModel,
    msg: string,
    isLoading: boolean;
    isSubmitted: boolean;
    emailPrincipal: pessoa_emailModel;
}


export default withRouter(class EditEmail extends Component<any> {
    state: State = {
        email: {} as pessoa_emailModel,
        msg: "",
        isLoading: true,
        isSubmitted: false,
        emailPrincipal: {} as pessoa_emailModel,
    }

    componentDidMount() {
        const id = this.props.router.params.id
        store.getState().user.user.pessoa_email.map(email => {
            if (email.id.toString() === id.toString()) {
                this.setState({ email, isLoading: false });
            }
            if (email.flg_principal === "S" && email.id.toString() !== id.toString()) {
                this.setState({emailPrincipal: email });
            }
        })
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({
            email: {
                ...this.state.email,
                [name]: value
            }
        });
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        api_app.atualizarEmail(this.state.email.id, this.state.email)
            .then(() => {
                const msg = "Seu email foi atualizado com sucesso!"; 
                this.setState({ msg, isSubmitted: true });
                this.props.router.updateStore();
            })
            .catch((error: any) => {
                if (error instanceof Error && error.message.includes("500")) {
                    this.setState({ msg: "Ocorreu um erro ao cadastrar o E-mail. Verifique os dados digitados e tente novamente." });
                } else {
                    this.setState({ msg: "Ocorreu um erro ao cadastrar o E-mail. Tente novamente mais tarde." });
                }
            });
    };
    
    render() {
        if (this.state.isLoading) {
            return <div><SpinnerCustom /></div>;
        }
        return <>
            <form onSubmit={this.handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Alterar E-mail"
                    />
                    <ButtonReturnCustom
                        link="/perfil"
                    />
                </div>
                <div className="grid gap-6 mb-6 ">
                    <InputCustom
                        label="E-mail:"
                        value={this.state?.email?.email || ''}
                        type="text"
                        name="email"
                        placeholder="E-mail"
                        onChange={this.handleChange}
                    />
                    <InputCustom
                        label="Observação:"
                        value={this.state?.email?.observacao || ''}
                        type="text"
                        name="observacao"
                        placeholder="Observação"
                        onChange={this.handleChange}
                    />
                    <ToggleCustom
                        label={this.state.emailPrincipal?.email !== undefined ? this.state.emailPrincipal?.email + " é seu email principal!" : "e-mail Principal?"}
                        value={this.state?.email.flg_principal || 'S'}
                        name='flg_principal'
                        onChange={this.handleChange}
                        disabled={this.state?.emailPrincipal?.email !== undefined ? true : false}
                        defaultValue={this.state?.email.flg_principal || 'N'}
                    />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    {!this.state.isSubmitted && (
                        <ButtonSaveCustom
                            type="submit"
                            name="Salvar"
                        />
                    )}
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{this.state.msg}</p>
            </form>
        </>
    }
})