import React, { Component } from "react";
import { TittleCustom } from "../../../../components/TittleCustom";
import { ButtonSaveCustom } from "../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../components/InputCustom";
import FileInputCustom from "../../../../components/FileInputCustom"; // Corrigido o nome do componente
import {
    pessoa_fisicaModel,
    pessoaModel,
    tipo_documentoModel
} from "../../../../resource/request";
import { store } from "../../../../redux/store";
import api_app from "../../../../apis/api_app";
import withRouter from "../../../../helpers/withRouter";
import { combineSlices } from "@reduxjs/toolkit";
import api_app_upload from "../../../../apis/apiUpload";
import { createUser } from "../../../../redux/user/slice";
import FormFiles from "../dadopessoal/FormFiles";

interface State {
    pessoa: pessoaModel,
    pessoa_fisica: pessoa_fisicaModel,
    msg: string;
    isSubmitted: boolean;

}


class FormProfissional extends Component<any, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            pessoa: store.getState().user?.user,
            pessoa_fisica: store.getState().user?.user.pessoa_fisica.find(() => true) || {} as pessoa_fisicaModel,
            msg: '',
            isSubmitted: false,

        };
    }



    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        var pessoa_fisica = this.state.pessoa.pessoa_fisica.find(() => true);
        this.setState({
            pessoa_fisica: {
                ...this.state.pessoa_fisica,
                [name]: value
            }
        });
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        api_app.atualizarPessoaFisica(this.state.pessoa_fisica.id, this.state.pessoa_fisica).then(x => {
            const msg = "Seus dados foram atualizados com sucesso!";
            this.setState({ msg, isSubmitted: true });
            this.props.router.updateStore();
        });
    };

    render() {
        return (
            <>
                <form onSubmit={this.handleSubmit}>
                    <div className="text-lg font-semibold text-gray-900 dark:text-white">
                        <TittleCustom
                            name="Redes Sociais"
                        />
                    </div>
                    <div className="grid gap-4 mb-4">
                        <InputCustom
                            label="Site:"
                            value={this.state?.pessoa_fisica?.site || ''}
                            type="text"
                            name="site"
                            placeholder="Site"
                            onChange={this.handleChange}
                        />
                        <InputCustom
                            label="Facebook:"
                            value={this.state?.pessoa_fisica?.facebook || ''}
                            type="text"
                            name="facebook"
                            placeholder="Facebook"
                            onChange={this.handleChange}
                        />
                        <InputCustom
                            label="Instagram:"
                            value={this.state?.pessoa_fisica?.instagram || ''}
                            type="text"
                            name="instagram"
                            placeholder="Instagram"
                            onChange={this.handleChange}
                        />
                        <InputCustom
                            label="Lattes:"
                            value={this.state?.pessoa_fisica?.lattes || ''}
                            type="text"
                            name="lattes"
                            placeholder="Lattes"
                            onChange={this.handleChange}
                        />
                        <InputCustom
                            label="X:"
                            value={this.state?.pessoa_fisica?.twitter || ''}
                            type="text"
                            name="twitter"
                            placeholder="X"
                            onChange={this.handleChange}
                        />
                        <InputCustom
                            label="Linkedin:"
                            value={this.state?.pessoa_fisica?.linkedin || ''}
                            type="text"
                            name="linkedin"
                            placeholder="Linkedin"
                            onChange={this.handleChange}
                        />
                        <InputCustom
                            label="TikTok:"
                            value={this.state?.pessoa_fisica?.tiktok || ''}
                            type="text"
                            name="tiktok"
                            placeholder="TikTok"
                            onChange={this.handleChange}
                        />
                    </div>

                    <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                        {!this.state.isSubmitted && (
                            <ButtonSaveCustom
                                type="submit"
                                name="Salvar"
                            />
                        )}
                    </div>
                    <p className="mt-2 text-sm text-green-600 dark:text-green-500"> {this.state.msg}</p>
                </form>

                <div>
                    <FormFiles usuario_id={2} />
                </div>

            </>
        );
    }
}

export default withRouter(FormProfissional);
