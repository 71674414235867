import api_app from "../../apis/api_app";
import { createUser } from "../../redux/user/slice";
import { store } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import SpinnerCustom from "../../components/SpinnerCustom";
import GroupCheck from "../helpers/GroupCheck";


export function Home() {
    const { keycloak } = useKeycloak();
    let navigate = useNavigate();
    let dispach = useDispatch();


    const [post, setPost] = useState(false);
    useEffect(() => {
        setPost(true);

    }, []);

    if (!post) {
        return <div
            className="flex justify-center items-center h-screen">
            <SpinnerCustom
            />
        </div>;
    }else{

            navigate('/agendamento-de-consulta');

    }
    return (

        <>
            <div>
                <p>Bem vindo {store.getState().user.user.nome}</p>
                <GroupCheck />
            </div>
        </>
    );
}
