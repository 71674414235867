import React, { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import SelectCustom, { Option } from "../../../../../components/SelectCustom";
import { SelectEspecialidadeCustum } from "./SelectEspecialidadesCustum";
import withRouter from "../../../../../helpers/withRouter";
import { pessoa_emailModel, pessoa_especialidadeModel, profissaoModel } from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import api_app from "../../../../../apis/api_app";
import FileInputCustom from "../../../../../components/FileInputCustom";
import { NumericFormat } from 'react-number-format';

interface State {
    especialidades: pessoa_especialidadeModel,
    msg: string,
    profissaoModel: profissaoModel;
    isSubmitted: boolean;
}

export default withRouter(class EditEspecialidade extends Component<any> {

    state: State = {
        especialidades: {
            pessoa_id: store.getState().user?.user.id,
            flg_status: 'A',
            flg_visivel: 'S',
            dat_criado_em: new Date(),
            num_especialidade_rqe: "",
            valor_atendimento_pa: 0,
            vlr_atendimento: 0,
        } as pessoa_especialidadeModel,
        msg: "",
        profissaoModel: {} as profissaoModel,
        isSubmitted: false,
    }

    componentDidMount() {
        this.buscarDadosDoBanco();
    }

    buscarDadosDoBanco() {
        const id = this.props.router.params.id;
        const espec = store.getState().user.user.pessoa_especialidade;
        espec.map(especialidades => {
            if (especialidades?.id?.toString() === id.toString()) {
                this.setState({ especialidades });
            }
        });
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        if (name === 'profissao') {
            this.setState({
                profissaoModel: {
                    ...this.state.profissaoModel,
                    id: parseInt(value)
                }
            });
        } else {
            this.setState({
                especialidades: {
                    ...this.state.especialidades,
                    [name]: value
                }
            });
        }
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.state.especialidades.especialidade_id = parseInt(this.state?.especialidades?.especialidade_id?.toString() || "");
        this.setState({
            ...this.state,
            especialidades: {
                ...this.state.especialidades,
            }
        });
        const x = store.getState().user.user.pessoa_especialidade?.find(x => x.especialidade_id === this.state.especialidades.especialidade_id);
        if (x?.id) {
            const msg = "Já existe esta especialidade cadastrada!";
            this.setState({ msg });
        } else {
            try {
                await api_app.atualizarEspecialidade(this.state.especialidades.id, this.state.especialidades);
                const msg = "Seus dados foram atualizados com sucesso!";
                this.setState({ msg, isSubmitted: true });
                this.props.router.updateStore();
            } catch (error) {
                console.error('Erro ao atualizar especialidade!', error);
                this.setState({ msg: 'Erro ao atualizar especialidade!' });
            }
        }
    };

    render() {
        return <>
            <form onSubmit={this.handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom name="Alterar Especialidade" />
                    <ButtonReturnCustom link="/dados-profissionais" />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <SelectEspecialidadeCustum onChange={this.handleChange} />
                    <InputCustom
                        label="UF:"
                        value={this.state.especialidades.uf || ''}
                        type="text"
                        name="uf"
                        placeholder="UF"
                        onChange={this.handleChange}
                    />
                    <InputCustom
                        label="Nº Registro/Nº RQE:"
                        value={this.state.especialidades.num_especialidade_rqe || ''}
                        type="text"
                        name="num_especialidade_rqe"
                        placeholder="Nº Especialidades/ Nº RQE"
                        onChange={this.handleChange}
                    />
                </div>
                <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                    Valor da Consulta:
                </label>
                <NumericFormat
                    value={this.state.especialidades.vlr_atendimento || ''}
                    onValueChange={(values) => {
                        const { floatValue } = values;
                        this.setState({
                            especialidades: {
                                ...this.state.especialidades,
                                vlr_atendimento: floatValue !== undefined ? floatValue : 0
                            }
                        });
                    }}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={true}
                    prefix="R$ "
                    displayType="input"
                    placeholder="R$ 0,00"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    autoComplete="on"
                />
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    <ButtonSaveCustom type="" name="Salvar" />
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{this.state.msg}</p>
            </form>
        </>
    }
});
