import React, { Component } from "react";
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import { SelectEspecialidadeCustum } from "./SelectEspecialidadesCustum";
import InputCustom from "../../../../../components/InputCustom";
import {
    pessoa_especialidadeModel,
    pessoaModel,
    profissaoModel
} from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import api_app from "../../../../../apis/api_app";
import withRouter from "../../../../../helpers/withRouter";
import FileInputCustom from "../../../../../components/FileInputCustom";
import SelectCustom, { Option } from "../../../../../components/SelectCustom";

const profissao: Option[] = [
    { key: "1", value: "Clinico Geral", disabled: false },
];

interface State {
    pessoa: pessoaModel;
    especialidades: pessoa_especialidadeModel;
    msg: string;
    profissaoModel: profissaoModel;
    isSubmitted: boolean;
}

export default withRouter(class CreateEspecialidade extends Component<any> {

    state: State = {
        pessoa: store.getState().user?.user,
        profissaoModel: {} as profissaoModel,
        especialidades: {
            pessoa_id: store.getState().user?.user.id,
            flg_status: 'A',
            flg_visivel: 'S',
            dat_criado_em: new Date(),
            num_especialidade_rqe: "",
            valor_atendimento_pa: 0,
            vlr_atendimento: 0,
        } as pessoa_especialidadeModel,
        msg: "",
        isSubmitted: false,
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;

        if (name === 'profissao') {
            this.setState({
                profissaoModel: {
                    ...this.state.profissaoModel,
                    id: parseInt(value)
                }
            });
        } else {
            this.setState({
                especialidades: {
                    ...this.state.especialidades,
                    [name]: value
                }
            });
        }
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.state.especialidades.especialidade_id = parseInt(this.state?.especialidades?.especialidade_id?.toString() || "");
        this.setState({
            ...this.state,
            especialidades: {
                ...this.state.especialidades,
            }
        })
        const x = store.getState().user.user.pessoa_especialidade?.find(x => x.especialidade_id === this.state.especialidades.especialidade_id);
        if (
            x?.id || 0
        ) {
            const msg = "Já existe esta especialidade cadastrada!";
            this.setState({ msg });
        } else {

            api_app.criarEspecialidade(this.state.especialidades).then(x => {
                const msg = "Seus dados foram atualizados com sucesso!";
                this.setState({ msg, isSubmitted: true });
                this.props.router.updateStore();
            }
            )
        }
    };

    render() {
        return (
            <>
                <form onSubmit={this.handleSubmit}>
                    <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                        <TittleCustom name="Adicionar Especialidade" />
                        <ButtonReturnCustom link="/dados-profissionais" />
                    </div>
                    <div>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                            <SelectEspecialidadeCustum onChange={this.handleChange} />
                            <InputCustom
                                label="UF:"
                                value={this.state.especialidades.uf || ''}
                                type="text"
                                name="uf"
                                placeholder="UF"
                                onChange={this.handleChange}
                            />
                            <InputCustom
                                label="Nº Registro/Nº RQE:"
                                value={this.state.especialidades.num_especialidade_rqe || ''}
                                type="number"
                                name="num_especialidade_rqe"
                                placeholder="Nº Especialidades/ Nº RQE"
                                onChange={this.handleChange}
                            />
                        </div>
                        <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                            Valor da Consulta:
                        </label>
                        <NumericFormat
                            value={this.state.especialidades.vlr_atendimento || ''}
                            onValueChange={(values) => {
                                const { floatValue } = values;
                                this.setState({
                                    especialidades: {
                                        ...this.state.especialidades,
                                        vlr_atendimento: floatValue !== undefined ? floatValue : 0
                                    }
                                });
                            }}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={true}
                            prefix="R$ "
                            displayType="input"
                            placeholder="R$ 0,00"
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" autoComplete="on"
                        />
                    </div>
                    <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                        {!this.state.isSubmitted && (
                            <ButtonSaveCustom
                                type="submit"
                                name="Salvar"
                            />
                        )}
                    </div>
                    <p className="mt-2 text-sm text-green-600 dark:text-green-500">{this.state.msg}</p>
                </form>
            </>
        )
    }
});
