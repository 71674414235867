import { Tabs } from "flowbite-react";
import { HiOutlineDocumentText, HiClipboardList, HiUserCircle, HiOutlineUserCircle } from "react-icons/hi";
import React, { useEffect, useState } from "react";
import CKEditorWrapper from "../../components/CKEditorCustom";
import { store } from "../../redux/store";
import { useParams } from "react-router-dom";
import api_app from "../../apis/api_app";
import { agendaModel } from "../../resource/request";
import { TittleCustom } from "../../components/TittleCustom";
import InputDisabled from "../../components/InputDisabled";
import { TextAreaCustom } from "../../components/TextAreaCustom";
import { useNavigate } from 'react-router-dom';
import SpinnerCustom from "../../components/SpinnerCustom";
import { Accordion, Timeline } from "flowbite-react";
import moment from "moment";
import { ButtonReturnCustom } from "../../components/ButtonReturnCustom";

export function DadosDoPaciente() {
    const [isPatient, setIsPatient] = useState(false);
    const [pessoaNome, setPessoaNome] = useState(store.getState().user?.user?.nome);
    const [image, setImage] = useState<any>(null);
    const [agenda, setAgenda] = useState({} as agendaModel);
    const [updateAgenda, setUpdateAgenda] = useState({} as agendaModel);
    const [editorContent, setEditorContent] = useState('');
    let params = useParams();
    const id = params.id;
    const navigate = useNavigate();


    useEffect(() => {
        api_app.getAgendaById(id).then((r) => {
            setAgenda(r.data);
            const documentos = r.data.pessoa_agenda_paciente_idTopessoa?.pessoa_documento?.filter((x: any) => x.tipo_documento_id == 7);
            if (documentos?.length > 0) {
                const primeiraImagem = documentos[0];
                setImage(primeiraImagem);
            } else {
                setImage("/FotoUser.jpg");
            }
            if (store.getState().user?.user?.id === r.data.pessoa_agenda_paciente_idTopessoa?.id) {
                setIsPatient(true);
            }
        });

    }, [id]);

    function AtualizaAgenda() {
        api_app.patchAgenda(id, updateAgenda).then(x => {
            console.log('Agenda Atualizada');
            console.log(x);
            if (updateAgenda.status_agenda_id === 5) {
                if (isPatient) {
                    navigate('/prontuario');
                }
                else {
                    navigate('/dados-profissionais/agenda');
                }
            }
        });
    }

    useEffect(() => {
        if (updateAgenda.status_agenda_id === 4 ||
            updateAgenda.status_agenda_id === 5 ||
            updateAgenda.avaliacao_profissional !== agenda.avaliacao_profissional ||
            updateAgenda.avaliacao_paciente !== agenda.avaliacao_paciente
        ) {
            AtualizaAgenda();
        }
    }, [updateAgenda]);

    if (agenda.id === undefined || agenda.id === 0) {
        return <>
            <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
                <SpinnerCustom />
            </div>
        </>
    }

    return (
        <>
            {!isPatient && (
                <div className="overflow-x-auto">
                    <div className="flex items-center justify-between rounded-t mb-2 dark:border-gray-600">
                        <TittleCustom
                            name="Dados do Paciente"
                        />
                        <ButtonReturnCustom
                            link="/dados-profissionais/meus-pacientes"
                        />
                    </div>
                    <Tabs>
                        <Tabs.Item title="Informações Gerais">
                            <div>
                                <form>
                                    <div className="flex justify-between items-center text-lg">
                                        <TittleCustom name="Dados Pessoais" />
                                    </div>
                                    <div className="flex flex-row justify-start items-center mb-6">
                                        <div className="flex-shrink-0 w-1/2 items-center">
                                            <div className="flex-shrink-0 ml-28 w-1/2 ">
                                                {image && image.id && (
                                                    <img
                                                        alt="Document"
                                                        src={`${process.env.REACT_APP_BASE_URL}/pessoa-documento/documento/${image.id}`}
                                                        className="max-w-full max-h-full"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex-1 ml-1 flex flex-col items-center mt-4">
                                            <div className="mb-2 w-full">
                                                <InputDisabled
                                                    label="Nome Completo:"
                                                    value={agenda.pessoa_agenda_paciente_idTopessoa?.nome || ''}
                                                    type="text"
                                                    name="nome"
                                                    placeholder="Nome Completo"
                                                />
                                            </div>
                                            <div className="mb-2 w-full">
                                                <InputDisabled
                                                    label="Nome Social:"
                                                    value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.nome_social || ''}
                                                    type="text"
                                                    name="nome_social"
                                                    placeholder="Nome Social"
                                                />
                                            </div>
                                            <div className="mb-2 w-full">
                                                <InputDisabled
                                                    label="CPF:"
                                                    value={agenda.pessoa_agenda_paciente_idTopessoa?.cpf_cnpj || ''}
                                                    type="text"
                                                    name="cpf_cnpj"
                                                    placeholder="000.000.000-00"
                                                />
                                            </div>
                                            <div className="mb-2 w-full">
                                                <InputDisabled
                                                    label="Data de Nascimento:"
                                                    value={agenda.pessoa_agenda_paciente_idTopessoa?.dat_nascimento_fundacao
                                                        ? moment(agenda.pessoa_agenda_paciente_idTopessoa?.dat_nascimento_fundacao, 'YYYY-MM-DD').startOf('day').format("DD/MM/YYYY") : ""}
                                                    type="text"
                                                    name="data_nascimento"
                                                    placeholder="00/00/0000"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                                        <InputDisabled
                                            label="Telefone:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_telefone?.find((x) => x.flg_principal === 'S')?.telefone || ''}
                                            type="text"
                                            name="telefone"
                                            placeholder="Telefone"
                                        />
                                        <InputDisabled
                                            label="E-mail:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_email?.find((x) => x.flg_principal === 'S')?.email || ''}
                                            type="text"
                                            name="email"
                                            placeholder="E-mail"
                                        />
                                        <InputDisabled
                                            label="Estado Civil:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.estado_civil?.estado_civil.toString() || ''}
                                            type="text"
                                            name="estado_civil"
                                            placeholder="Estado Civil"
                                        />
                                        <div className="mb-2 w-full">
                                            <InputDisabled
                                                label="RG/RNE:"
                                                value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.rg || ''}
                                                type="text"
                                                name="rg"
                                                placeholder="RG/RNE"
                                            />
                                        </div>
                                        <InputDisabled
                                            label="Órgão Expedidor/UF:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.rg_orgao_expedidor || ''}
                                            type="text"
                                            name="rg_orgao_expedidor"
                                            placeholder="Órgão Expedidor / UF"
                                        />
                                        <InputDisabled
                                            label="Nº Do Cartão Do SUS:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.cartao_sus || ''}
                                            type="text"
                                            name="cartao_sus"
                                            placeholder="Nº Do Cartão Do SUS"
                                        />
                                        <InputDisabled
                                            label="Nome Da Mãe:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.nome_mae || ''}
                                            type="text"
                                            name="nome_mae"
                                            placeholder="Nome Da Mãe"
                                        />
                                        <InputDisabled
                                            label="Nome Do Pai:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.nome_pai || ''}
                                            type="text"
                                            name="nome_pai"
                                            placeholder="Nome Do Pai"
                                        />
                                        <InputDisabled
                                            label="Etnia:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.raca_etnia?.raca_etnia.toString() || ''}
                                            name="raca_etnia"
                                            type="text"
                                            placeholder="Etnia"
                                        />
                                        <InputDisabled
                                            label="Gênero:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.tipo_genero?.tipo_genero.toString() || ''}
                                            name="tipo_genero"
                                            type="text"
                                            placeholder="Gênero"
                                        />
                                        <InputDisabled
                                            label="Pronome Gênero:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.tipo_genero_pronome?.tipo_genero_pronome.toString() || ''}
                                            name="tipo_genero_pronome"
                                            type="text"
                                            placeholder="Pronome Gênero"
                                        />
                                        <InputDisabled
                                            label="Identidade De Gênero:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.tipo_identidade_genero?.tipo_identidade_genero.toString() || ''}
                                            name="tipo_identidade_genero"
                                            type="text"
                                            placeholder="Identidade De Gênero"
                                        />
                                        <InputDisabled
                                            label="Profissão:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.profissao || ''}
                                            type="text"
                                            name="profissao"
                                            placeholder="Profissão"
                                        />
                                        <InputDisabled
                                            label="Pis/Pasep:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_fisica?.find((x) => true)?.pis_pasep || ''}
                                            type="text"
                                            name="pis_pasep"
                                            placeholder="Pis/Pasep"
                                        />
                                    </div>
                                </form>
                            </div>
                        </Tabs.Item>

                        <Tabs.Item title="Histórico Médico">
                            <div>
                                <form>
                                    <div className="flex justify-between items-center text-lg mb-2">
                                        <TittleCustom
                                            name="Minha saúde"
                                        />
                                    </div>
                                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                                        <h1>Fuma: {agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.flg_tabagismo == 'S' ? 'Sim' : 'Não'}</h1>
                                        <h1>Bebe: {agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.flg_etilismo == 'S' ? 'Sim' : 'Não'}</h1>
                                        <h1>Usou ou Usa Drogas: {agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.flg_utilizou_drogas == 'S' ? 'Sim' : 'Não'}</h1>
                                        <h1>Pratica Exercícios Físicos: {agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.flg_pratica_exercicio == 'S' ? 'Sim' : 'Não'}</h1>
                                    </div>
                                    <div className="grid gap-4 mb-4">
                                        <TextAreaCustom
                                            label="Você possui alguma alergia?"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.alergias || ''}
                                            name='alergias'
                                            disabled
                                        />
                                        <TextAreaCustom
                                            label="Liste aqui as doenças e tratamentos que você tenha realizado anteriormente:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.doencas_previas || ''}
                                            name='doencas_previas'
                                            disabled
                                        />
                                        <TextAreaCustom
                                            label="Informe aqui se estiver com alguma doença ou passando por algum tratamento:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.doencas_atuais || ''}
                                            name='doencas_atuais'
                                            disabled
                                        />
                                        <TextAreaCustom
                                            label="Informe se e quais medicações de uso contínuo você faz uso:"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.medicacoes_continuas || ''}
                                            name='medicacoes_continuas'
                                            disabled
                                        />
                                        <TextAreaCustom
                                            label="Já fez alguma cirurgia? Qual? Em que ano?"
                                            value={agenda.pessoa_agenda_paciente_idTopessoa?.pessoa_paciente?.find((x) => true)?.cirurgias || ''}
                                            name='cirurgias'
                                            disabled
                                        />
                                    </div>
                                </form>
                            </div>
                        </Tabs.Item>

                        <Tabs.Item title="Histórico de Prontuário">
                            <Accordion>
                                {agenda.pessoa_agenda_paciente_idTopessoa?.agenda_agenda_paciente_idTopessoa?.filter((x) => x.status_agenda_id === 5)
                                    .map((agenda, index) => (
                                    <Accordion.Panel key={index}>
                                        <Accordion.Title className="bg-gray-200 p-4 rounded-lg shadow-md">
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p style={{ marginRight: '10px' }}>

                                                    {agenda.dia ? moment(agenda.dia, 'YYYY-MM-DD').startOf('day').format("DD/MM/YYYY") : '-'}{' '}-{' '}
                                                    {agenda.horario ? moment(agenda.horario, 'YYYY-MM-DD HH:mm').startOf('hour').format("HH") +":"+ moment(agenda.horario, 'YYYY-MM-DD HH:mm').startOf('minutes').format("mm") : '-'}{' '} -
                                                </p>
                                                <p>
                                                    Médico: {agenda.pessoa?.nome || '-'} -{' '}
                                                    {agenda.especialidade?.especialidade || '-'}
                                                </p>
                                            </div>
                                        </Accordion.Title>
                                        <Accordion.Content className="p-4">
                                            <Timeline className="mt-4">
                                                <Timeline.Item>
                                                    <Timeline.Content>
                                                        <Timeline.Body>
                                                            <div className="ml-2">
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: `${agenda.prontuario?.find((x) => x)?.parecer ||
                                                                            ''
                                                                            }<br/><br/>${editorContent}`,
                                                                    }}
                                                                />
                                                            </div>
                                                        </Timeline.Body>
                                                    </Timeline.Content>
                                                </Timeline.Item>
                                            </Timeline>
                                        </Accordion.Content>
                                    </Accordion.Panel>
                                )) ?? []}
                            </Accordion>
                        </Tabs.Item>
                    </Tabs>

                </div>
            )}
        </>
    );
}
