import { Checkbox } from "flowbite-react";
import { Component } from "react";
import { ButtonCreateCustom } from "../../../../../components/ButtonCreateCustom";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonEditCustom } from "../../../../../components/ButtonEditCustom";
import { ButtonDeleteCustom } from "../../../../../components/ButtonDeleteCustom";
import ButtonSearchCuston from "../../../../../components/ButtonSearchCustom";
import { pessoaModel } from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import InputSearchCuston from "../../../../../components/InputSearchCustom";

interface State {
    pessoa: pessoaModel;
    searchTerm: string;
}

export class ListEndereco extends Component<any> {

    state: State = {
        pessoa: store.getState().user?.user,
        searchTerm: "",
    };

    componentDidMount() {

    }

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchTerm: event.target.value });
    };

    getFilteredEnderecos() {
        const { searchTerm, pessoa } = this.state;
        if (!searchTerm) return pessoa?.pessoa_endereco || [];

        return (pessoa?.pessoa_endereco || []).filter((end) =>
            [end.cep, end.estado, end.cidade, end.bairro, end.endereco, end.numero, end.complemento]
                .map(field => field ?? '')
                .some(field => field.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }

    render() {
        const filteredEnderecos = this.getFilteredEnderecos();

        return <>
            <div className="text-lg font-semibold text-gray-900 dark:text-white">
                <TittleCustom name="Dados de Endereço" />
            </div>
            <div
                className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <ButtonCreateCustom link="/adicionar-endereco" name="Adicionar Endereço" />
                <InputSearchCuston
                    name="search"
                    value={this.state.searchTerm}
                    onChange={this.handleSearchChange}
                />
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                CEP
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Estado
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Cidade
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Bairro
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Endereço
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Número
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Complemento
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opções
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredEnderecos.map((end) => (
                            <tr
                            className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${end.flg_visivel === 'S' ? '' : 'hidden'}`}
                            >
                                <td scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {end.cep}
                                </td>

                                <td className="px-6 py-4">
                                    {end.estado}
                                </td>
                                <td className="px-6 py-4">
                                    {end.cidade}
                                </td>
                                <td className="px-6 py-4">
                                    {end.bairro}
                                </td>
                                <td className="px-6 py-4">
                                    {end.endereco}
                                </td>
                                <td className="px-6 py-4">
                                    {end.numero}
                                </td>
                                <td className="px-6 py-4">
                                    {end.complemento}

                                </td>
                                <td className="px-6 py-4">
                                    <div className="flex items-center">
                                        <ButtonEditCustom
                                            link={"/alterar-endereco/" + end.id}
                                        />
                                        {end.flg_principal !== "S" && (
                                            <ButtonDeleteCustom
                                                link={"/pessoa-endereco/" + end.id}
                                            />
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    }
}