import { Component } from "react";
import { Menu } from "../Menu";
import { DadosDoPix } from "./dadosdopix/ListDadosDoPix";
import { DadosBancarios } from "./dadosbancarios/ListDadosBancarios";
import { DadosDosCartoes } from "./dadocartao/ListDadosDosCartoes";

export class DadosFinanceiros extends Component {
    render() {
        return <div>

            <Menu />
            <div>
                <DadosBancarios />
            </div>
            <div>
                <DadosDoPix />
            </div>
            <div>
                <DadosDosCartoes />
            </div>
        </div>
    }
}