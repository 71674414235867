import {
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {store} from "../redux/store";

import {createUser} from "../redux/user/slice";
import api_app from "../apis/api_app";
import {useKeycloak} from "@react-keycloak/web";
import {useState} from "react";

function withRouter(Component) {


    function ComponentWithRouterProp(props) {
        const {keycloak, initialized} = useKeycloak();
        let {pessoa, setPessoa} = useState(null);

        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams()
        let selet = useSelector( state => (state.user));
        let dispach = useDispatch();

        const updateStore = function (){
            if (keycloak.authenticated){
                api_app.findByPessoa(keycloak?.idTokenParsed?.email).then(result => {
                    dispach(createUser(result));
                });
            }
        }

        return (
            <Component
                {...props}
                router={{location, navigate, params, keycloak, selet, dispach,updateStore }}
            />
        );
    }

    return ComponentWithRouterProp;
}

export default withRouter