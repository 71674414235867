import { createSlice } from '@reduxjs/toolkit';
import {pessoaModel} from "../../resource/request";

export interface userRd{
    user: pessoaModel
}

const initialState = {
   user: {} as pessoaModel,
    stepInit: false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        createUser: (state, action) => {
            state.user = action.payload;
        },
        setStepInit: (state, action) => {
            state.stepInit = action.payload;
        },
    }
});

export const { createUser,setStepInit } = userSlice.actions;
export default userSlice.reducer