import apis from "./apis";
const api_app = {

    registrar: async (obj) => {
        let response = await apis.post("/agenda", obj);
        return response;
    },

    atualizarPessoa: async (id, obj) => {
        let response = await apis.patch(`/pessoa/${id}`, obj);
        return response;
    },

    atualizarPessoaFisica: async (id, obj) => {
        let response = await apis.patch(`/pessoa-fisica/${id}`, obj);
        return response;
    },

    atualizarPessoaPaciente: async (id, obj) => {
        let response = await apis.patch(`/pessoa-paciente/${id}`, obj);
        return response;
    },

    delete: async (path) => {
        let response = await apis.patch(`${path}`, {
            flg_status: "E",
            flg_visivel: "N",

        });
        return response;
    },

    atualizarEndereco: async (id, obj) => {
        console.log(obj);
        let response = await apis.patch(`/pessoa-endereco/${id}`, obj);
        return response;
    },

    atualizarEmail: async (id, obj) => {
        console.log(obj);
        let response = await apis.patch(`/pessoa-email/${id}`, obj);
        return response;
    },

    criarEmail: async (id, obj) => {
        console.log(obj);
        let response = await apis.post(`/pessoa-email/`, obj);
        return response;
    },

    findByPessoa: async (email) => {
        let response = await apis.get(`/pessoa/email/${email}`);
        return response.data;
    },

    findEspecialidades: async () => {
        let response = await apis.get(`/especialidade`);
        return response.data;
    },

    findProfissao: async () => {
        let response = await apis.get(`/profissao`);
        return response.data;
    },

    findTipoGenero: async () => {
        let response = await apis.get(`/tipo-genero`);
        return response.data;
    },

    findEtnia: async () => {
        let response = await apis.get(`/raca-etnia`);
        return response.data;
    },

    findTipoIdentidadeGenero: async () => {
        let response = await apis.get(`/tipo-indentidade-genero`);
        return response.data;
    },

    findTipoGeneroPronome: async () => {
        let response = await apis.get(`/tipo-genero-pronome`);
        return response.data;
    },

    findEstadoCivil: async () => {
        let response = await apis.get(`/estado-civil`);
        return response.data;
    },

    criarEndereco: async (obj) => {
        let response = await apis.post(`/pessoa-endereco/`, obj);
        return response;
    },

    criartTelefone: async (obj) => {
        let response = await apis.post(`/pessoa-telefone/`, obj);
        return response;
    },

    atualizarTelefone: async (id, obj) => {
        let response = await apis.patch(`/pessoa-telefone/${id}`, obj);
        return response;
    },

    criarEspecialidade: async (obj) => {
        let response = await apis.post(`/pessoa-especialidades/`, obj);
        return response;
    },

    atualizarEspecialidade: async (id, obj) => {
        let response = await apis.patch(`/pessoa-especialidades/${id}`, obj);
        return response;
    },

    findTipoUsuario: async () => {
        let response = await apis.get(`/tipo-usuario`);
        return response.data;
    },

    findTipoDocumento: async () => {
        let response = await apis.get(`/tipo-documento`);
        return response.data;
    },

    getDoc: async (id) => {
        let response = await apis.get(`/pessoa-documento/documento/${id}`);
        return response;
    },

    getPessoa: async (id) => {
        let response = await apis.get(`/pessoa/${id}`);
        return response;
    },

    getAgenda: async (obj) => {
        let response = await apis.post(`/agenda/query`, obj);
        return response;
    },

    postAgendas: async (obj) => {
        let response = await apis.post(`/agenda`, obj);
        return response;
    },

    getAgendaPessoa: async (obj) => {
        let response = await apis.post(`/agenda/query/pessoa`, obj);
        return response;
    },

    getAgendaProfissional: async (id) => {
        let response = await apis.post(`/agenda/query/profissional/${id}`);
        return response;
    },

    getAgendaMedicos: async (obj) => {
        let response = await apis.post(`/pessoa/query`, obj);
        return response;
    },

    getMeusPacientes: async (obj) => {
        let response = await apis.post(`/pessoa/meus-pacientes`, obj);
        return response;
    },

    getAgendaById: async (id) => {
        let response = await apis.get(`/agenda/${id}`);
        return response;
    },

    patchAgenda: async (id, obj) => {
        let response = await apis.patch(`/agenda/${id}`, obj);
        return response;
    },

    postProntuario: async (obj) => {
        let response = await apis.post(`/prontuario`, obj);
        return response;
    },

    criarPessoaProfissional: async (id, obj) => {
        let response = await apis.post(`/pessoa-profissional/`, obj);
        return response;
    },
}
export default api_app;