import React, { useEffect, useState } from "react";
import { Accordion, Timeline } from "flowbite-react";
import { agendaModel } from "../../../resource/request";
import SpinnerCustom from "../../../components/SpinnerCustom";
import api_app from "../../../apis/api_app";
import { store } from "../../../redux/store";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

function Prontuario() {
    const [minhaAgenda, setMinhaAgenda] = useState<agendaModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editorContent, setEditorContent] = useState('');

    useEffect(() => {
        const fetchAgenda = async () => {
            try {
                const response = await api_app.getAgendaPessoa({
                    "data_inicial": "",
                    "data_final": "",
                    "horario_inicial": "",
                    "horario_final": "",
                    "page": 1,
                    "limit": 50,
                    "pessoa_id": store.getState().user.user.id,
                });
                console.log(response);
                setMinhaAgenda(response.data.data);
            } catch (error) {
                console.error("Erro ao buscar agenda:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAgenda();
    }, []);

    function formatDate(date: Date | string | number) {
        const formattedDate = new Date(date);
        const day = formattedDate.getDate();
        const month = formattedDate.getMonth() + 1;
        const year = formattedDate.getFullYear();
        return `${day}/${month}/${year}`;
    }

    function formatTime(date: Date | string | number) {
        const formattedDate = new Date(date);
        const hours = formattedDate.getHours().toString().padStart(2, '0');
        const minutes = formattedDate.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }


    if (minhaAgenda?.filter(x => x.status_agenda_id === 5)?.length < 1) {
        return <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <p>Sem agenda? Cadastre uma nova Agenda:</p>
                <Link to="/agendamento-de-consulta"
                    className="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                    style={{ backgroundColor: "#598c89 ", marginLeft: "10px" }}>
                    Marcar Consulta
                </Link>
            </div>
        </>
    }
    return (
        <div className="overflow-x-auto p-4">
            {isLoading ? (
                <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
                    <SpinnerCustom />
                </div>
            ) : (


                <Accordion>
                    {minhaAgenda?.filter(x => x.status_agenda_id === 5).map((agenda, index) => (
                        <Accordion.Panel key={index}>
                            <Accordion.Title className="bg-gray-200 p-4 rounded-lg shadow-md">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ marginRight: '10px' }}>
                                        {agenda.dia ? moment(agenda.dia, 'YYYY-MM-DD').startOf('day').format("DD/MM/YYYY") : '-'} -
                                        {agenda.horario ?  moment(agenda.horario, 'YYYY-MM-DD HH:mm').startOf('hour').format("HH") +":"+ moment(agenda.horario, 'YYYY-MM-DD HH:mm').startOf('minutes').format("mm") : '-'} -
                                    </p>
                                    <p>
                                        Médico: {agenda.pessoa?.nome} - {agenda.especialidade?.especialidade}
                                    </p>
                                </div>
                            </Accordion.Title>
                            <Accordion.Content className="p-4">
                                <Timeline className="mt-4">
                                    <Timeline.Item>
                                        <Timeline.Content>
                                            <Timeline.Body>
                                                <Timeline.Body>
                                                    <Timeline.Body>
                                                        <Timeline.Body>
                                                            <div className="ml-2">
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: `${agenda.prontuario?.find((x) => x)?.parecer || ''}<br/><br/>${editorContent}`
                                                                    }}
                                                                />
                                                            </div>
                                                        </Timeline.Body>
                                                    </Timeline.Body>
                                                </Timeline.Body>
                                            </Timeline.Body>
                                        </Timeline.Content>
                                    </Timeline.Item>
                                </Timeline>
                            </Accordion.Content>
                        </Accordion.Panel>
                    ))}
                </Accordion>
            )}
        </div>
    );
}

export default Prontuario;
