import React, { ChangeEvent, Component } from "react";
import { TittleCustom } from "../../../components/TittleCustom";
import { ButtonSaveCustom } from "../../../components/ButtonSaveCustom";
import InputCustom from "../../../components/InputCustom";
import {
    FilterDto,
    agendaModel,
    pessoaModel,
    pessoa_especialidadeModel,
    pessoa_fisicaModel,
    profissaoModel
} from "../../../resource/request";
import withRouter from "../../../helpers/withRouter";
import api_app from "../../../apis/api_app";
import { SelectEspecialidadeCustum } from "../meuperfil/dadosprofissionais/especialidades/SelectEspecialidadesCustum";
import { store } from "../../../redux/store";
import { Pagination } from "flowbite-react";
import { format, parseISO } from 'date-fns';
import SpinnerCustom from "../../../components/SpinnerCustom";
import { AboutCustumPage } from "../../../components/AboutCustom";
import SelectCustom, { Option } from "../../../components/SelectCustom";
import moment from 'moment-timezone';

interface State {
    agendas: agendaModel[];
    pessoa: pessoaModel;
    showModal: boolean;
    especialidades: pessoa_especialidadeModel;
    profissaoModel: profissaoModel;
    currentPage: number;
    perPage: number;
    totalpg: number;
    filter: FilterDto;
    loading: boolean;
    agendaSelecionada: agendaModel;
    confirmMessage: string;
    dia: Date;
    especialidade: Option[];
    selectedEspecialidade: string;

}
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

class ProntoAtendimento extends Component<any, State> {
    state: State = {
        agendas: [],
        pessoa: store.getState().user.user,
        showModal: false,
        profissaoModel: {} as profissaoModel,
        especialidades: {
            pessoa_id: store.getState().user?.user.id,
            flg_status: 'A',
            flg_visivel: 'S',
            dat_criado_em: new Date(),
            num_especialidade_rqe: "",
            valor_atendimento_pa: 0,
            vlr_atendimento: 0
        } as pessoa_especialidadeModel,
        currentPage: 1,
        perPage: 50,
        totalpg: 50,
        filter: {} as FilterDto,
        loading: true,
        agendaSelecionada: {} as agendaModel,
        confirmMessage: '',
        dia: new Date(),
        especialidade: [],
        selectedEspecialidade: '',
    };

    componentDidMount(): void {
        moment.tz.setDefault('America/Sao_Paulo'); // Replace with your preferred timezone

        this.fetchAgendas(1);
    }

    handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: value
            }
        });
    };

    getFilter = () => {
        const { filter } = this.state;
        const currentDate = new Date();
        const formattedInitialDate = filter.perido_inicial && typeof filter.perido_inicial === 'string'
            ? format(parseISO(filter.perido_inicial), 'yyyy-MM-dd')
            : format(currentDate, 'yyyy-MM-dd');

        const formattedFinalDate = filter.perido_final && typeof filter.perido_final === 'string'
            ? format(parseISO(filter.perido_final), 'yyyy-MM-dd')
            : format(currentDate, 'yyyy-MM-dd');

        const formattedInitialhours = filter.hora_inicio && typeof filter.hora_inicio === 'string'
            ? format(parseISO(`2000-01-01T${filter.hora_inicio}`), 'HH:mm')
            : format(currentDate, 'HH:mm');

        const initialTime = filter.hora_inicio && typeof filter.hora_inicio === 'string'
            ? parseISO(`2000-01-01T${filter.hora_inicio}`)
            : currentDate;
        initialTime.setHours(initialTime.getHours() + 4);
        const formattedFinalhours = filter.hora_final && typeof filter.hora_final === 'string'
            ? format(parseISO(`2000-01-01T${filter.hora_final}`), 'HH:mm')
            : format(initialTime, 'HH:mm');

        return {
            data_inicial: formattedInitialDate,
            data_final: formattedFinalDate,
            horario_inicial: formattedInitialhours,
            horario_final: formattedFinalhours,
            especialidades: filter.especialidades,
            page: this.state.currentPage,
            limit: this.state.perPage || 50
        };
    }

    toggleModal = (id: any) => {
        const agenda = this.state.agendas.find((x) => x.id === id);
        if (agenda) {
            const especialidade = agenda.pessoa?.pessoa_especialidade.map((esp) => ({
                key: esp.especialidade_id?.toString() || '',
                value: esp?.especialidade?.especialidade?.toString() || '',
                disabled: false,
            })) || [];

            this.setState({
                showModal: true,
                agendaSelecionada: agenda,
                especialidade: especialidade
            });
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    }

    Filter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({
            loading: true
        })
        api_app.getAgenda(this.getFilter()).then((resp) => {
            this.setState({
                agendas: resp.data.data,
                totalpg: resp.data.totalPages,
                currentPage: 1,
                loading: false
            });
        }).catch((error) => {
            console.error('Erro ao buscar agendas:', error);
        });
    };

    fetchAgendas = (page: number) => {
        api_app.getAgenda(this.getFilter()).then((resp) => {

                this.setState({
                    agendas: resp.data?.data,
                    totalpg: resp.data?.totalPages,
                    currentPage: page,
                    loading: false
                });
        });
    };



    handleChangeEspecialidade = (event: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({
            especialidades: {
                ...this.state.especialidades,
                [name]: value
            },
            filter: {
                ...this.state.filter,
                especialidades: value
            }
        });
    };

    handleNextPage = (page: number) => {
        this.setState({ currentPage: page }, () => {
            this.fetchAgendas(page);
        });
    };


    handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        this.setState({
            loading: true
        })
        api_app.getAgendaMedicos(this.getFilter()).then((resp) => {
            this.setState({
                agendas: resp.data.data,
                totalpg: resp.data.totalPages,
                currentPage: resp.data.page,
                loading: false

            });
        }).catch((error) => {
            console.error('Erro ao buscar agendas:', error);
        });
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;

        this.setState((prevState) => {
            const updatedState: Partial<State> = {
                ...prevState,
                [name]: value
            };
            return updatedState as State;
        });
    };

    handleSubmitModal = async (event: React.FormEvent) => {
        event.preventDefault();

        const { agendaSelecionada, selectedEspecialidade, } = this.state;

        if (!agendaSelecionada.id) {
            this.setState({ confirmMessage: 'Agenda selecionada não encontrada.' });
            return;
        }
        try {
            await api_app.patchAgenda(agendaSelecionada.id, {
                id: this.state.agendaSelecionada.id,
                agendado_por: this.state.agendaSelecionada.id,
                status_agenda_id: 3,
                especialidade_id: parseInt(selectedEspecialidade),
                paciente_id: this.state.pessoa.id,
            });
            this.setState({
                confirmMessage: 'Consulta confirmada com sucesso!',
            });
            this.fetchAgendas(this.state.currentPage);
            this.props.router.navigate('/agendamento-de-consulta');
        } catch (error) {
            console.error('Erro ao confirmar consulta:', error);
            this.setState({ confirmMessage: 'Erro ao confirmar consulta.' });
        }
    }

    render() {
        const dataFormatada = this.state.agendaSelecionada?.dia ?  moment(this.state.agendaSelecionada?.dia, 'YYYY-MM-DD').startOf('day').format("DD/MM/YYYY") : '-';
        const horarioFormatado = moment(this.state.agendaSelecionada?.horario, 'YYYY-MM-DD HH:mm').startOf('hour').format("HH") +":"+ moment(this.state.agendaSelecionada?.horario, 'YYYY-MM-DD HH:mm').startOf('minutes').format("mm");
        const currentDate = new Date();

        return (
            <>
                <div>
                    <TittleCustom name="Pronto Atendimento" />
                </div>
                <form onSubmit={this.Filter}>
                    <div className="grid gap-6 md:grid-cols-2">
                        {/* <InputCustom
                            label="Período Inicial:"
                            value={formattedInitialDate}
                            type="date"
                            name="perido_inicial"
                            placeholder="Período Inicial"
                            onChange={this.handleFilterChange}
                        />
                        <InputCustom
                            label="Período Final:"
                            value={formattedFinalDate}
                            type="date"
                            name="perido_final"
                            placeholder="Período Final"
                            onChange={this.handleFilterChange}
                        /> */}
                        {/* <InputCustom
                            label="Hora de Início:"
                            value={formattedInitialhours}
                            type="time"
                            name="hora_inicio"
                            placeholder="Hora de Início"
                            onChange={this.handleFilterChange}
                        />
                        <InputCustom
                            label="Hora Final:"
                            value={formattedFinalhours}
                            type="time"
                            name="hora_final"
                            placeholder="Hora Final"
                            onChange={this.handleFilterChange}
                        /> */}
                        <SelectEspecialidadeCustum
                            profissao_id={this.state?.profissaoModel?.id || 0}
                            value={this.state?.especialidades?.id?.toString() || ""}
                            onChange={this.handleChangeEspecialidade}
                            requiredEspecialidade={false}
                            requiredProfissao={false}
                        />
                        <div>
                            <button
                                type="submit"
                                className="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-900 rounded-lg border border-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-900 dark:hover:bg-blue-800 dark:focus:ring-blue-800"
                            >
                                <svg
                                    className="w-4 h-4 me-2"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                    />
                                </svg>
                                Pesquisar
                            </button>
                        </div>
                    </div>
                </form>
                {this.state.loading ? (
                    <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
                        <SpinnerCustom />
                    </div>
                ) : (
                    <div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Data
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Hora
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Médico
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Especialidade
                                        </th>
                                        <th scope="col" className="px-6 py-3  text-center">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state?.agendas?.
                                    filter( a => (moment(a.horario, 'YYYY-MM-DD HH:mm').startOf('hour').format("HH") > moment(new Date(), 'YYYY-MM-DD HH:mm').startOf('hour').format("HH")) ).
                                    map((agenda) => (
                                        <tr key={agenda.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {agenda.dia ? moment(agenda.dia, 'YYYY-MM-DD').startOf('day').format("DD/MM/YYYY") : '-'}

                                            </td>
                                            <td className="px-6 py-4">
                                                {
                                                    agenda.horario ? moment(agenda.horario, 'YYYY-MM-DD HH:mm').startOf('hour').format("HH") +":"+ moment(agenda.horario, 'YYYY-MM-DD HH:mm').startOf('minutes').format("mm") : '-'}
                                            </td>
                                            <td className="px-6 py-4">
                                                {agenda.pessoa?.nome}
                                            </td>
                                            <td className="px-6 py-4">
                                                {agenda?.pessoa?.pessoa_especialidade?.map((z, index) => (
                                                    <span key={index}> - {z.especialidade?.especialidade}</span>
                                                ))}
                                            </td>
                                            <td className="flex items-center justify-center">
                                                <button
                                                    data-modal-target="default-modal"
                                                    data-modal-toggle="default-modal"
                                                    className="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 rounded-lg"
                                                    style={{ backgroundColor: "#3b5179" }}
                                                    onClick={() => this.toggleModal(agenda.id)}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                                    </svg>
                                                    Consultar
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex items-center justify-end mt-4">
                            <div className="flex overflow-x-auto sm:justify-center">
                                    <Pagination
                                        currentPage={this.state?.currentPage || 1}
                                        totalPages={this.state?.totalpg || 0}
                                        onPageChange={this.handleNextPage}
                                        showIcons previousLabel="anterior"
                                        nextLabel="próxima"

                                    />


                            </div>
                        </div>
                    </div>
                )}
                {this.state.showModal && (
                    <div className="fixed top-0 right-0 left-0 bottom-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75">
                        <div id="default-modal" className="w-full max-w-3xl p-4">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-full h-auto max-h-[90vh] overflow-y-auto">
                                <div className="flex items-center justify-between p-4 md:p-3 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Agendamento de Consulta
                                    </h3>
                                    <button
                                        type="button"
                                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                        onClick={this.closeModal}
                                    >
                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Fechar modal</span>
                                    </button>
                                </div>
                                <form onSubmit={this.handleSubmitModal} >
                                    <div className="p-4 md:p-5 space-y-4">
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                            <div className="flex flex-col justify-center items-center md:col-span-1">
                                                <AboutCustumPage
                                                    label={this.state.agendaSelecionada?.pessoa?.nome || ""}
                                                    name={this.state?.agendaSelecionada?.pessoa?.pessoa_profissional?.find(() => true)?.resumo_curriculo || ""}
                                                    src={
                                                        this.state.agendaSelecionada?.pessoa?.pessoa_documento?.find(x => x.tipo_documento_id === 7) ?
                                                            REACT_APP_BASE_URL + "/pessoa-documento/documento/" + this.state.agendaSelecionada?.pessoa?.pessoa_documento?.find(x => x.tipo_documento_id === 7)?.id
                                                            : ''}
                                                    pessoaprofission={this.state.agendaSelecionada?.pessoa?.pessoa_fisica?.find(() => true) || {} as pessoa_fisicaModel}
                                                />
                                            </div>
                                            <div className="flex flex-col justify-center items-center md:col-span-1">
                                                <div className="w-full">
                                                    <div className="grid gap-2 mb-2">
                                                        <label className="block  text-sm font-medium text-gray-900 dark:text-white">Data:</label>
                                                        <input
                                                            value={dataFormatada}
                                                            disabled
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                        />
                                                        <label className="block  text-sm font-medium text-gray-900 dark:text-white">Hora:</label>
                                                        <input
                                                            value={horarioFormatado}
                                                            disabled
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                        />
                                                        <SelectCustom
                                                            label="Especialidade:"
                                                            name="selectedEspecialidade"
                                                            defaultValue="Selecione"
                                                            options={this.state.especialidade}
                                                            onChange={this.handleChange}
                                                            required
                                                        />
                                                        {this.state.confirmMessage && (
                                                            <div className="mt-4">
                                                                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{this.state.confirmMessage}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                                        <div className="ml-auto">
                                            <button
                                                type="button"
                                                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 ml-2 mb-2"
                                                onClick={this.closeModal}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                        <div className="ml-2">
                                            <ButtonSaveCustom
                                                name="Confirmar"
                                                type="submit"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default withRouter(ProntoAtendimento);