import { Link } from "react-router-dom";

export interface PropertiesCustom {
    "name": string,
    "link": string,
}

export const ButtonCreateCustom = (props: PropertiesCustom) => {

    return <>
        <Link to={props.link} 
         className="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
         style={{ backgroundColor: "#3b5179" }} 
        
        >{props.name}</Link>
    </>

}