import React from 'react';

export interface PropertiesCustom {
    name: string;
    type: string;
    disabled?: boolean
    event?: () => void; 
}

export const ButtonSaveCustom: React.FC<PropertiesCustom> = (props) => {
    const backgroundColor = props.disabled ? '#a7eb51' : '#a7eb51';

    return (
        <button
            onClick={props.event} 
            className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 border border-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
            style={{ backgroundColor }} 
            disabled={props.disabled}
        >
          {props.disabled ? (
                <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                </svg>
            ) : (
                props.name
            )}
        </button>
    );
};