import axios from "axios";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;


const apisUpload = axios.create({
    baseURL: REACT_APP_BASE_URL,
});

apisUpload.interceptors.request.use(async (config) => {
    config.headers.setContentType('multipart/form-data')
    return config;
});


const api_app_upload = {

    register: async ( obj) => {
        let response = await apisUpload.post(`/pessoa/created`, obj);
        return response.data;
    },
}

export default api_app_upload;
