import {Menu} from "../Menu";
import DadosHiostricos from "./DadosHistoricos";


export function DadosPacientes() {


    return <div>
        <Menu/>

        {/*<div>*/}
        {/*    <PlanosParticulares />*/}
        {/*</div>*/}

        <div>
            <DadosHiostricos/>
        </div>
    </div>


}