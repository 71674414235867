import { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import {  pessoaModel, pessoa_chave_pixModel } from "../../../../../resource/request";
import SelectCustom, { Option } from "../../../../../components/SelectCustom";

interface State {
    dados_pix: pessoa_chave_pixModel,
    instituicao_financeira: Option[];
    titular: pessoaModel
}

export class CreateDadosDoPix extends Component {

    state: State = {
        dados_pix: {} as pessoa_chave_pixModel,
        instituicao_financeira: [{ key: "1", value: "Carregando...", disabled: true }],
        titular: {} as pessoaModel
    }

    render() {
        return <>
            <form>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Adicionar Dados do Pix"
                    />
                    <ButtonReturnCustom
                        link="/dados-financeiros"
                    />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <SelectCustom
                        label="Instituição Financeira:"
                        name="instituicao_financeira"
                        defaultValue="Selecione"
                        options={this.state.instituicao_financeira}
                    />
                    <InputCustom
                        label="Chave Pix:"
                        value={this.state?.dados_pix.chave_pix}
                        type="text"
                        name="name"
                        placeholder="Chave Pix"
                    />
                    <InputCustom
                        label="Titular:"
                        value={this.state?.titular?.nome || ''}
                        type="text"
                        name="nome"
                        placeholder="Titular"
                    />
                    <InputCustom
                        label="Descrição:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Descrição"
                    />
                </div>
                <div>
                    <ButtonSaveCustom
                       type="submit" 
                        name="Salvar"
                    />
                </div>
            </form >
        </>
    }
}