import React, { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import { pessoa_telefoneModel } from "../../../../../resource/request";
import withRouter from "../../../../../helpers/withRouter";
import { store } from "../../../../../redux/store";
import api_app from "../../../../../apis/api_app";
import { ToggleCustom } from "../../../../../components/ToggleCustom";
import SpinnerCustom from "../../../../../components/SpinnerCustom";

interface State {
    telefone: pessoa_telefoneModel;
    msg: string
    isLoading: boolean;
    isSubmitted: boolean;
    telefonePrincipal: pessoa_telefoneModel;
}

export default withRouter(class EditTelefone extends React.Component<any> {
    state: State = {
        telefone: {} as pessoa_telefoneModel,
        telefonePrincipal: {} as pessoa_telefoneModel,
        msg: "",
        isLoading: true,
        isSubmitted: false,
    };

    componentDidMount() {
        this.buscarDadosDoBanco()
    }

    buscarDadosDoBanco() {

        const id = this.props.router.params.id

        const telefoneResponse = store.getState().user.user.pessoa_telefone
        telefoneResponse.map(telefone => {
            console.log(telefone)
            if (telefone.id.toString() === id.toString()) {

                this.setState({ telefone, isLoading: false, });
            }

            if (telefone.flg_principal === "S" && telefone.id.toString() !== id.toString()) {
                this.setState({telefonePrincipal: telefone });
            }

        })
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({
            telefone: {
                ...this.state.telefone,
                [name]: value
            }
        });
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        api_app.atualizarTelefone(this.state.telefone.id, this.state.telefone).then(x => {
            const msg = "Seu telefone foi atualizado com com suscesso!";
            this.setState({ msg, isSubmitted: true});
            this.props.router.updateStore();
        });
    };

    render() {
        if (this.state.isLoading) {
            return <div><SpinnerCustom /></div>;
        }
        return <>
            <form onSubmit={this.handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Alterar Telefone"
                    />
                    <ButtonReturnCustom
                        link="/perfil"
                    />
                </div>
                <div className="grid gap-6 mb-6">
                    <InputCustom
                        label="Telefone:"
                        value={this.state.telefone?.telefone?.toString() || ""}
                        type="text"
                        mask="(99) 9 99999999"
                        name="telefone"
                        placeholder="(00) 0 00000000"
                        onChange={this.handleChange}
                    />
                    <InputCustom
                        label="Descrição:"
                        value={this.state.telefone?.observacao?.toString() || ''}
                        type="text"
                        name="observacao"
                        placeholder="Descrição"
                        onChange={this.handleChange}
                    />
                    <ToggleCustom
                        label="Telefone Principal?"
                        value={this.state?.telefone?.flg_principal || 'S'}
                        name='flg_principal'
                        onChange={this.handleChange}
                        disabled={this.state?.telefonePrincipal?.id !== undefined ? true : false}
                        defaultValue={this.state?.telefone?.flg_principal || 'N'}
                    />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    {!this.state.isSubmitted && (
                        <ButtonSaveCustom
                            type="submit"
                            name="Salvar"
                        />
                    )}
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{this.state.msg}</p>
            </form>
        </>
    }
})
