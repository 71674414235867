import { ChangeEvent, Component } from "react";
import { TittleCustom } from "../../../components/TittleCustom";
import { SelectEspecialidadeCustum } from "../meuperfil/dadosprofissionais/especialidades/SelectEspecialidadesCustum";
import { Pagination } from "flowbite-react";
import {
    FilterDto,
    pessoa_especialidadeModel,
    profissaoModel,
    pessoaModel, agendaModel
} from "../../../resource/request";
import { store } from "../../../redux/store";
import ButtonAppointmentScheduling from "../../../components/ButtonAppointmentScheduling";
import withRouter from "../../../helpers/withRouter";
import api_app from "../../../apis/api_app";
import { Accordion } from "flowbite-react";
import ButtonNewQueryCustom from "../../../components/ButtonNewQueryCustom";
import SpinnerCustom from "../../../components/SpinnerCustom";
import moment from "moment-timezone";
import InputSearchCuston from "../../../components/InputSearchCustom";

interface State {
    agendas: pessoaModel[];
    minhaAgenda: agendaModel[];
    showModal: boolean;
    especialidades: pessoa_especialidadeModel;
    profissaoModel: profissaoModel;
    filter: FilterDto;
    filter2: FilterDto;
    loading: boolean;
    searchTerm: string;
    searchTermMinhaAgenda: string;
}

class Agenda extends Component<any, State> {

    constructor(props: {}) {
        super(props);
        this.state = {
            agendas: [],
            minhaAgenda: [],
            showModal: false,
            profissaoModel: {} as profissaoModel,
            especialidades: {} as pessoa_especialidadeModel,
            filter: {
                perido_inicial: new Date(),
                perido_final: new Date(),
                profissao: '0',
                especialidade: '0',
                page: 1,
                limit: 50,
                totalPages: 2,
                currentPage: 1,
            } as FilterDto,
            filter2: {
                perido_inicial: new Date(),
                perido_final: new Date(),
                profissao: '0',
                especialidades: '0',
                page: 1,
                limit: 5,
                totalPages: 1,
                currentPage: 1,
            } as FilterDto,
            loading: true,
            searchTerm: '',
            searchTermMinhaAgenda: '',
        };

    }

    componentDidMount(): void {
        this.fetchAgendas();
        this.fetchAgendasPessoa();
    }

    handleFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: value
            }
        });
    };

    getFilter = () => {
        const { filter } = this.state;

        return {
            data_inicial: filter.perido_inicial || moment().format('YYYY-MM-DD'),
            data_final: filter.perido_final || moment().format('YYYY-MM-DD'),
            especialidades: filter.especialidades,
            profissao: filter.profissao,
            page: filter.page || 1,
            limit: filter.limit || 50,
            totalPages: filter.totalPages || 1,
            currentPage: filter.currentPage || 1,
            pessoa_id: store.getState().user?.user?.id || 0
        } as FilterDto;
    };

    getFilter2 = () => {
        const { filter2 } = this.state;

        return {
            data_inicial: filter2.perido_inicial || moment().format('YYYY-MM-DD'),
            data_final: filter2.perido_final || moment().format('YYYY-MM-DD'),
            especialidades: filter2.especialidades,
            profissao: filter2.profissao,
            page: filter2.page || 1,
            limit: filter2.limit || 50,
            totalPages: filter2.totalPages || 1,
            currentPage: filter2.currentPage || 1,
            pessoa_id: store.getState().user?.user?.id || 0
        } as FilterDto;
    };

    Filter = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        this.fetchAgendas();
    };

    fetchAgendas = () => {
        api_app.getAgendaMedicos(this.getFilter()).then((resp) => {
            this.setState({
                agendas: resp.data.data,
                loading: false,
                filter: {
                    ...this.state.filter,
                    totalPages: resp.data.totalPages,
                    currentPage: resp.data.page,
                }
            })
        }).catch((error) => {
            console.error('Erro ao buscar agendas:', error);
        });
    };

    fetchAgendasPessoa = () => {
        api_app.getAgendaPessoa(this.getFilter2()).then((resp) => {
            this.setState({
                minhaAgenda: resp.data.data,
                loading: false,
                filter2: {
                    ...this.state.filter2,
                    totalPages: resp.data.totalPages,
                    currentPage: resp.data.page,
                }
            });
        });
    };

    handleChangeEspecialidade = (event: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({
            filter: {
                ...this.state.filter,
                profissao: value,
                especialidades: value
            }
        });
    };

    handleNextPage = (page: number) => {
        this.setState({
            filter: {
                ...this.state.filter,
                currentPage: page,
                page: page
            }
        }, () => {
            this.fetchAgendas();
        });
    };

    handleNextPage2 = (page: number) => {
        this.setState({
            filter2: {
                ...this.state.filter2,
                currentPage: page,
                page: page
            }
        }, () => {
            this.fetchAgendasPessoa();
        });
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchTerm: event.target.value });
    };

    getFilteredAgendas = () => {
        const { agendas, searchTerm } = this.state;
        if (!agendas) return [];

        return agendas.filter(agenda =>
            agenda.nome?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            agenda.pessoa_especialidade?.some(z =>
                z.especialidade?.especialidade.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    };

    handleSearchChangeMinhaAgenda = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchTermMinhaAgenda: event.target.value });
    };

    getFilteredMinhaAgenda = () => {
        const { minhaAgenda, searchTermMinhaAgenda } = this.state;
        const lowerSearchTerm = searchTermMinhaAgenda.toLowerCase();
    
        if (!minhaAgenda) return [];
    
        return minhaAgenda.filter(agenda =>
            agenda.pessoa?.nome?.toLowerCase().includes(lowerSearchTerm) ||
            agenda.especialidade?.especialidade?.toLowerCase().includes(lowerSearchTerm) ||
            (agenda.dia && moment(agenda.dia.toString()).format('DD/MM/YYYY').toLowerCase().includes(lowerSearchTerm)) ||
            (agenda.horario && moment(agenda.horario).format('HH:mm').toLowerCase().includes(lowerSearchTerm))
        );
    };


    render() {
        return (
            <>
                <Accordion>
                    <Accordion.Panel>
                        <Accordion.Title>
                            <TittleCustom name="Minhas Próximas Consultas" />
                        </Accordion.Title>
                     
                        <Accordion.Content>
                            <div className="flex justify-end mt-4 mb-3">
                                <InputSearchCuston
                                    value={this.state.searchTermMinhaAgenda}
                                    onChange={this.handleSearchChangeMinhaAgenda}
                                    name="searchTerm2"
                                />
                            </div>

                            <div className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Data
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Hora
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Médico
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Especialidade
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                situação
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Valor
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Acessar consulta
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.getFilteredMinhaAgenda().map((agenda) => (
                                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                <td className="px-6 py-3">
                                                    {agenda.dia ? moment(agenda.dia, 'YYYY-MM-DD').startOf('day').format("DD/MM/YYYY") : '-'}
                                                </td>
                                                <td className="px-6 py-3">
                                                    {agenda.horario ?  moment(agenda.horario, 'YYYY-MM-DD HH:mm').startOf('hour').format("HH") +":"+ moment(agenda.horario, 'YYYY-MM-DD HH:mm').startOf('minutes').format("mm") : '-'}
                                                </td>
                                                <td className="px-6 py-3">
                                                    {agenda.pessoa?.nome}
                                                </td>
                                                <td className="px-6 py-3">
                                                    {agenda.especialidade?.especialidade}
                                                </td>
                                                <td className="px-6 py-3">
                                                    {agenda.status_agenda?.status_agenda}
                                                </td>
                                                <td className="px-6 py-3">
                                                    {agenda.valor}
                                                </td>
                                                <td className="px-6 py-3">
                                                    {(agenda?.status_agenda_id === 3 || agenda?.status_agenda_id === 4) && (
                                                        <ButtonNewQueryCustom
                                                            link={'/dados-profissionais/agenda/atendimento-consulta/' + agenda.id}
                                                            name="Acessar Consulta"
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex items-center justify-end mt-4">
                                <div className="flex sm:justify-center">
                                    <Pagination
                                        currentPage={this.state.filter2.currentPage || 1}
                                        totalPages={this.state.filter2.totalPages || 1} onPageChange={this.handleNextPage2}
                                        showIcons
                                        previousLabel="anterior"
                                        nextLabel="próxima"
                                    />
                                </div>
                            </div>
                        </Accordion.Content>
                    </Accordion.Panel>
                </Accordion>
                <div>
                    <TittleCustom
                        name="Agendamento de Consulta"
                    />

                </div>
                <div>
                    <div className="mb-5">
                        <form onSubmit={this.Filter}>
                            <div className="grid gap-6 md:grid-cols-2">
                                <div className="mb-4">
                                    <label htmlFor="perido_inicial" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data Inicial:</label>
                                    <input
                                        type="date"
                                        id="perido_inicial"
                                        name="perido_inicial"
                                        min={moment().tz('America/Sao_Paulo').format('YYYY-MM-DD')}
                                        value={moment(this.state.filter.perido_inicial).format('YYYY-MM-DD') || ''}
                                        onChange={this.handleFilterChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="perido_final" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data Final:</label>
                                    <input
                                        type="date"
                                        id="perido_final"
                                        name="perido_final"
                                        min={moment(this.state.filter.perido_inicial).startOf("day").format('YYYY-MM-DD') || ''}
                                        value={moment(this.state.filter.perido_final).startOf("day").format('YYYY-MM-DD') || ''}
                                        onChange={this.handleFilterChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    />
                                </div>
                                <SelectEspecialidadeCustum
                                    profissao_id={parseInt(this.state?.filter?.profissao || "")}
                                    value={this.state?.filter?.profissao || ""}
                                    onChange={this.handleChangeEspecialidade}
                                    requiredEspecialidade={false}
                                    requiredProfissao={false}
                                />
                                <div className="flex justify-between items-center">
                                    <button
                                        type="submit"
                                        className="inline-flex items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-900 rounded-lg border border-blue-800 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-900 dark:hover:bg-blue-800 dark:focus:ring-blue-800"
                                    >
                                        <svg
                                            className="w-4 h-4 me-2"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                            />
                                        </svg>
                                        Pesquisar
                                    </button>
                                </div>
                                <div className="ml-auto">
                                    <InputSearchCuston
                                        value={this.state.searchTerm}
                                        onChange={this.handleSearchChange}
                                        name="searchTerm"
                                    />
                                </div>
                            </div>
                        </form>
                    </div >
                    {
                        this.state.loading ? (
                            <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
                                <SpinnerCustom />
                            </div>
                        ) : (
                            <div>
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    MÉDICO
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    ESPECIALIDADE
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-center">
                                                    OPÇÕES
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.getFilteredAgendas().map((agenda) => (
                                                <tr key={agenda.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                    <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {agenda?.nome}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {agenda?.pessoa_especialidade?.map((z, index) => (
                                                            <span key={index}>{z.especialidade?.especialidade}</span>
                                                        ))}
                                                    </td>
                                                    <td className="px-6 py-4 text-center">
                                                        <div className="flex items-center justify-center w-full">
                                                            <ButtonAppointmentScheduling
                                                                link={"/agendamento-de-consulta/agendar-consulta/" + agenda.id}
                                                                name="Agendar Consulta"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>


                                </div>
                                <div className="flex items-center justify-end mt-4">
                                    <div className="flex overflow-x-auto sm:justify-center">
                                        <Pagination
                                            currentPage={this.state.filter.currentPage || 1}
                                            totalPages={this.state.filter.totalPages || 1}
                                            onPageChange={this.handleNextPage}
                                            showIcons
                                            previousLabel="anterior"
                                            nextLabel="próxima"
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div >
            </>
        );
    }
}

export default withRouter(Agenda);
