import React, { useState } from 'react';
import api_app from "../apis/api_app";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { store } from "../redux/store";
import { createUser } from "../redux/user/slice";

interface PropertiesCustom {
    link: string;
}

export const ButtonDeleteCustom = (props: PropertiesCustom) => {
    const { keycloak, initialized } = useKeycloak();
    let dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [msg, setMsg] = useState('');
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleDelete = () => {
        setMsg("Removido com sucesso");
        api_app.delete(props.link).then(x => {
            window.location.reload();
        });
    };

    return (
        <>
            <button onClick={toggleModal}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>
            </button>

            {showModal && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" onClick={toggleModal} className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                    Você tem certeza que deseja excluir?
                                </h3>
                                <button onClick={handleDelete} type="button"
                                    className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 border border-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                                    style={{ backgroundColor: '#a7eb51' }}
                                >
                                    Sim, Eu tenho!
                                </button>
                                <button onClick={toggleModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-white bg-red-600 hover:bg-red-800 rounded-lg inline-flex items-center text-center">
                                    Não, Cancelar
                                </button>
                                <p className=" text-green-600"> {msg}</p>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};