import React, { Component } from "react";
import { TittleCustom } from "../../components/TittleCustom";
import InputSearchCustom from "../../components/InputSearchCustom";
import { agendaModel, pessoaModel } from "../../resource/request";
import api_app from "../../apis/api_app";
import { Pagination } from "flowbite-react";
import SpinnerCustom from "../../components/SpinnerCustom";
import { Tabs } from "flowbite-react";
import { HiClipboardList, HiUserCircle } from "react-icons/hi";
import { Accordion, Timeline } from "flowbite-react";
import moment from "moment-timezone";
import { store } from "../../redux/store";
import { Link } from "react-router-dom";

interface State {
    agendas: agendaModel[];
    searchTerm: string;
    selectedPessoa: pessoaModel | null;
    currentPage: number;
    totalpg: number;
    loading: boolean;
    error: string | null;
}

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export class MeusPacientes extends Component<any, State> {
    state: State = {
        agendas: [],
        searchTerm: '',
        selectedPessoa: null,
        currentPage: 1,
        totalpg: 1,
        loading: false,
        error: null,
    };

    componentDidMount() {
        this.fetchPessoa(1);
    }

    fetchPessoa = (page: number) => {
        this.setState({ loading: true });
        api_app.getMeusPacientes(
            {
                "data_inicial": "2024-08-13",
                "data_final": "2024-08-13",
                "horario_inicial": "",
                "horario_final": "",
                "page": 1,
                "limit": 50,
                "nome": this.state.searchTerm,
                "pessoa_id": store.getState().user.user.id
            }
        )
            .then((resp) => {
                this.setState({
                    agendas: resp.data.data,
                    currentPage: resp.data.page,
                    totalpg: resp.data.totalPages,
                    loading: false,
                    error: null
                });
            })
            .catch(error => {
                this.setState({
                    error: 'Erro ao buscar dados',
                    loading: false
                });
                console.error('Erro ao buscar dados:', error);
            });
    };

    handleViewMore = (pessoa: pessoaModel) => {
        this.setState({ selectedPessoa: pessoa });
    };

    handleCloseCard = () => {
        this.setState({ selectedPessoa: null });
    };

    handleNextPage = (page: number) => {
        this.setState({ currentPage: page });
        this.fetchPessoa(page);
    };
    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchTerm: event.target.value });
    };

    getFilteredAgendas = () => {
        const { agendas, searchTerm } = this.state;

        if (!agendas) return [];

        const trimmedSearchTerm = searchTerm.trim().toLowerCase();

        return agendas.filter(agenda => {
            return  agenda.pessoa_agenda_paciente_idTopessoa?.cpf_cnpj?.toLowerCase().includes(trimmedSearchTerm) ||
            agenda.pessoa_agenda_paciente_idTopessoa?.nome?.toLowerCase().includes(trimmedSearchTerm);

        });
    };


    render() {
        const { selectedPessoa, currentPage, totalpg, loading, error } = this.state;
        const filteredAgendas = this.getFilteredAgendas();

        return (
            <>
                <div className="flex items-center justify-between mb-6">
                    <div className="text-lg font-semibold text-gray-900 dark:text-white">
                        <TittleCustom name="Meus Pacientes" />
                    </div>
                    <div className="w-full sm:w-auto sm:ml-4">
                    <InputSearchCustom
                            name="search"
                            value={this.state.searchTerm}
                            onChange={this.handleSearchChange}
                        />
                    </div>
                </div>
                {loading ? (
                    <div className="flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
                        <SpinnerCustom />
                    </div>
                ) : (
                    <>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Nome
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            E-Mail
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Telefone
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            CPF
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Ver Mais
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {filteredAgendas.map((ag) => (
                                        <tr
                                            key={ag.pessoa_agenda_paciente_idTopessoa?.id}
                                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                        >
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {ag.pessoa_agenda_paciente_idTopessoa?.nome || ''}
                                            </th>
                                            <td className="px-6 py-4">
                                                {ag.pessoa_agenda_paciente_idTopessoa?.pessoa_email?.find((x) => x.flg_principal === 'S')?.email || ''}
                                            </td>
                                            <td className="px-6 py-4">
                                                {ag.pessoa_agenda_paciente_idTopessoa?.pessoa_telefone?.find((x) => x.flg_principal === 'S')?.telefone || ''}
                                            </td>
                                            <td className="px-6 py-4">
                                                {ag.pessoa_agenda_paciente_idTopessoa?.cpf_cnpj || ''}
                                            </td>
                                            <td className="px-6 py-4 ml-4">
                                                <Link
                                                    to={'/dados-profissionais/meus-pacientes/dados-do-paciente/' + ag.id}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                    </svg>
                                                </Link>
                                                {/* <button
                                                    onClick={() => this.handleViewMore(ag.pessoa_agenda_paciente_idTopessoa || {} as pessoaModel)}
                                                    aria-label={`Ver mais sobre ${ag.pessoa_agenda_paciente_idTopessoa?.nome || ''}`}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                    </svg>
                                                </button> */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex items-center justify-end mt-4">
                            <div className="flex overflow-x-auto sm:justify-center">
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalpg}
                                    onPageChange={this.handleNextPage}
                                    showIcons
                                    previousLabel="anterior"
                                    nextLabel="próxima"
                                />
                            </div>
                        </div>
                    </>
                )}
                {selectedPessoa && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="absolute inset-0 bg-black opacity-50" aria-hidden="true"></div>
                        <div className="relative bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-xl">
                            <div className="flex justify-between items-center mb-4">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                    {selectedPessoa.nome}
                                </h5>
                                <button
                                    onClick={this.handleCloseCard}
                                    aria-label="Fechar detalhes do paciente"
                                    className="text-gray-600 dark:text-white hover:text-gray-900 dark:hover:text-gray-300"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <Tabs aria-label="Tabs with icons" >
                                <Tabs.Item active title="Detalhes do Paciente" icon={HiUserCircle}>
                                    <div className="flex flex-col md:flex-row bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow">
                                        <img
                                            className="object-cover w-full h-96 md:h-auto md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg"
                                            src={
                                                selectedPessoa.pessoa_documento?.find(x => x.tipo_documento_id === 7)
                                                    ? REACT_APP_BASE_URL + "/pessoa-documento/documento/" + selectedPessoa.pessoa_documento.find(x => x.tipo_documento_id === 7)?.id
                                                    : '/path/to/default-image.jpg'
                                            }
                                            alt="Sem Foto"
                                        />
                                        <div className="flex flex-col justify-between p-4 leading-normal">
                                            <p className="dark:text-white"><strong>E-Mail:</strong> {selectedPessoa.pessoa_email?.find((x) => x.flg_principal === 'S')?.email || ''}</p>
                                            <p className="dark:text-white"><strong>Telefone:</strong> {selectedPessoa.pessoa_telefone?.find((x) => x.flg_principal === 'S')?.telefone || ''}</p>
                                            <p className="dark:text-white"><strong>Data de Nascimento:</strong> {moment(selectedPessoa.dat_nascimento_fundacao?.toString() || new Date().toString()).format('DD/MM/YYYY')}</p>
                                            <p className="dark:text-white"><strong>Estado Civil:</strong> {selectedPessoa.pessoa_fisica?.find((x) => true)?.estado_civil?.estado_civil || ''}</p>
                                            <p className="dark:text-white"><strong>Etnia:</strong> {selectedPessoa.pessoa_fisica?.find((x) => true)?.raca_etnia?.raca_etnia || ''}</p>
                                            <p className="dark:text-white"><strong>Gênero:</strong> {selectedPessoa.pessoa_fisica?.find((x) => true)?.tipo_genero?.tipo_genero || ''}</p>
                                            <p className="dark:text-white"><strong>Pronome Gênero:</strong> {selectedPessoa.pessoa_fisica?.find((x) => true)?.tipo_genero_pronome?.tipo_genero_pronome || ''}</p>
                                            <p className="dark:text-white"><strong>Identidade De Gênero:</strong> {selectedPessoa.pessoa_fisica?.find((x) => true)?.tipo_identidade_genero?.tipo_identidade_genero || ''}</p>
                                        </div>
                                    </div>
                                </Tabs.Item>
                                <Tabs.Item title="Consultas" icon={HiClipboardList}>
                                    <Accordion>
                                        <Accordion.Panel>
                                            <Accordion.Title className="bg-gray-200 p-4 rounded-lg shadow-md">
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p style={{ marginRight: '10px' }}>
                                                        Dia:   Hora:
                                                    </p>
                                                    <p>
                                                        Médico:
                                                    </p>
                                                </div>
                                            </Accordion.Title>
                                            <Accordion.Content className="p-4">
                                                <Timeline className="mt-4">
                                                    <Timeline.Item>
                                                        <Timeline.Content>
                                                            <Timeline.Body>
                                                                <div className="ml-2">
                                                                    Prontuario
                                                                </div>
                                                            </Timeline.Body>
                                                        </Timeline.Content>
                                                    </Timeline.Item>
                                                </Timeline>
                                            </Accordion.Content>
                                        </Accordion.Panel>
                                    </Accordion>
                                </Tabs.Item>
                            </Tabs>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
