import React, { Component } from "react";
import SelectCustom, { Option } from "../../../../../components/SelectCustom";
import api_app from "../../../../../apis/api_app";
import { profissaoModel } from "../../../../../resource/request";

interface Props {
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    profissao_id?: number | null;
    requiredProfissao?: boolean; 
    requiredEspecialidade?: boolean;
}

interface State {
    especialidades: Option[];
    selectedEspecialidade: string;
    profissao: Option[];
    profissaoModel: profissaoModel;
}

export class SelectEspecialidadeCustum extends Component<Props, State> {
    state: State = {
        especialidades: [{ key: "1", value: "Carregando...", disabled: true }],
        selectedEspecialidade: this.props.value || "Selecione",
        profissaoModel: {} as profissaoModel,
        profissao: [{ key: "1", value: "Carregando...", disabled: true }],
    };

    componentDidMount(): void {
        this.buscarDadosDoBanco();
    }

    async getEspecialidades(): Promise<any[]> {
        return await api_app.findEspecialidades();
    }

    async buscarEspecialidades(profissao_id: number) {
        try {
            const especialidades = await this.getEspecialidades();
            this.setState({ especialidades: this.filtrarEspecialidades(especialidades, profissao_id) });
        } catch (error) {
            console.error("Erro ao buscar especialidades:", error);
        }
    }

    filtrarEspecialidades(especialidades: any[], profissao_id: number): Option[] {
        if (profissao_id !== 0) {
            return especialidades
                .filter((F: any) => F.profissao_id === profissao_id)
                .map((especialidade: any) => ({
                    key: especialidade.id.toString(),
                    value: especialidade.especialidade,
                    disabled: false,
                }));
        } else {
            return especialidades.map((especialidade: any) => ({
                key: especialidade.id.toString(),
                value: especialidade.especialidade,
                disabled: false,
            }));
        }
    }

    async buscarDadosDoBanco() {
        const profissaResponse = await this.getProfissao();
        const profissao = profissaResponse.map((profissao: any) => ({
            key: profissao.id.toString(),
            value: profissao.tipo_profissao,
            disabled: false
        }));
        this.setState({
            profissao
        });
        const profissaoId = this.props.profissao_id || 0;
        await this.buscarEspecialidades(profissaoId);
    }

    async getProfissao(): Promise<any[]> {
        try {
            return await api_app.findProfissao();
        } catch (error) {
            console.error("Erro ao obter as profissões:", error);
            throw error;
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { onChange } = this.props;
        const { name, value } = event.target;

        if (name === "profissao") {
            this.buscarEspecialidades(parseInt(value));
        }

        if (onChange) {
            onChange(event);
        } else {
            this.setState({ selectedEspecialidade: value });
        }
    };

    render() {
        const { requiredProfissao = true, requiredEspecialidade = true } = this.props; 
        return (
            <>
                <SelectCustom
                    label="Profissão:"
                    name="profissao"
                    defaultValue={this.state.profissaoModel?.tipo_profissao?.toString() || "Selecione"}
                    options={this.state.profissao}
                    onChange={this.handleChange}
                    required={requiredProfissao} 
                />
                <SelectCustom
                    label="Especialidades:"
                    name="especialidade_id"
                    defaultValue={this.state.selectedEspecialidade || ""}
                    onChange={this.handleChange}
                    options={this.state.especialidades}
                    required={requiredEspecialidade}  

                />
            </>
        );
    }
}
