import React, { Component } from "react";
import { TittleCustom } from "../../../../components/TittleCustom";
import { ButtonSaveCustom } from "../../../../components/ButtonSaveCustom";
import FileInputCustom from "../../../../components/FileInputCustom";
import {
    pessoaModel,
    tipo_documentoModel
} from "../../../../resource/request";
import { store } from "../../../../redux/store";
import api_app from "../../../../apis/api_app";
import withRouter from "../../../../helpers/withRouter";

import api_app_upload from "../../../../apis/apiUpload";
import { createUser } from "../../../../redux/user/slice";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
interface State {
    pessoa: pessoaModel,
    msg: string;
    tipo_documento?: tipo_documentoModel[],
    formFiles: FormData
    AddDocumento?: {
        pessoa_id: number
        tipo_documento_id?: string[]
        id?: number
        source?: string,
    },
    isSubmitted: boolean;
}

interface Props {
    showTitle?: boolean;
    showButton?: boolean;
    usuario_id: number;
}

class FormFiles extends Component<any, State> {


    constructor(props: any) {
        super(props);
        this.state = {
            pessoa: store.getState().user?.user,
            msg: '',
            tipo_documento: [],
            formFiles: new FormData(),
            AddDocumento: {
                pessoa_id: store.getState().user.user.id,
                tipo_documento_id: []
            },
            isSubmitted: false,
        };
    }

    componentDidMount() {
        this.buscaDb();
    }

    async buscaDb() {
        const docsti = (await this.getDocumentosTipos()).filter(x => x.tipo_usuario_id == this.props.usuario_id);

        this.setState({
            tipo_documento: docsti
        });
    }

    async getDocumentosTipos(): Promise<tipo_documentoModel[]> {
        try {
            return await api_app.findTipoDocumento();
        } catch (error) {
            console.error("Erro ao obter os tipos de documentos:", error);
            throw error;
        }
    }

    handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const f = this.state.formFiles;
            const tipo = event.target.name;
            const data = this.state.AddDocumento;
            if (!data?.tipo_documento_id?.find((x) => x == tipo)) {
                data?.tipo_documento_id?.push(tipo);
            }
            f.append('files', file);
            this.setState({ formFiles: f, AddDocumento: data });
            if (!this.props.showButton) {
                this.sendFiles(this.state.pessoa.id);
                this.setState({
                    formFiles: new FormData(),
                    AddDocumento: {
                        pessoa_id: store.getState().user.user.id,
                        tipo_documento_id: []
                    }
                })
            }
        }
        console.log(this.state.AddDocumento);
    };

    async sendFiles(numberPessoaID: number) {
        if (this.state.formFiles.has('files')) {
            const f = this.state.formFiles;
            const jsonData = JSON.stringify(this.state.AddDocumento);
            f.append('createFileDto', jsonData);
            api_app_upload.register(this.state.formFiles).then(x => {
                if (!this.props.showButton) {
                    const msg = 'Comprovante salvo com sucesso'
                    this.setState({
                        msg: msg
                    });
                }
            }
            )
        }
    }

    handleSubmitFiles = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (this.state.pessoa?.id == null) {
            api_app.findByPessoa(this.props.router.keycloak?.idTokenParsed?.email).then(result => {
                this.props.router.dispach(createUser(result));
                const { pessoa_id } = result;
                this.sendFiles(pessoa_id);
            }).then(() => {
                const msg = 'Comprovante salvo com sucesso'
                this.setState({
                    msg: msg,
                    isSubmitted: true
                });
                this.props.router.updateStore();
            });
        } else {
            this.sendFiles(this.state.pessoa.id).then(() => {
                const msg = 'Comprovante salvo com sucesso'
                this.setState({
                    msg: msg,
                    isSubmitted: true
                });
                this.props.router.updateStore();
            });
        }
    }

    render() {
        const { showTitle = true, showButton = true } = this.props;

        return (
            <>
                <form onSubmit={this.handleSubmitFiles}>
                    {showTitle && (
                        <div className="text-lg font-semibold text-gray-900 dark:text-white">
                            <TittleCustom
                                name="Meus Documentos "
                            />
                        </div>
                    )}
                    <div className="mb-6">
                        {this.state?.tipo_documento?.map(d => (
                            <div key={d.id} >
                                <FileInputCustom
                                    label={d?.descricao?.toString() || ''}
                                    name={d?.id?.toString() || ''}
                                    value=""
                                    onChange={this.handleChangeFiles}
                                    linklToFile={this.state?.pessoa?.pessoa_documento?.find(x => x.tipo_documento_id == d?.id) ?
                                        REACT_APP_BASE_URL + "/pessoa-documento/documento/" + this.state?.pessoa?.pessoa_documento?.find(x => x.tipo_documento_id == d?.id)?.id : ''}
                                />
                            </div>
                        ))}
                    </div>
                    {!this.state.isSubmitted && showButton && (
                        <ButtonSaveCustom
                            type="submit"
                            name="Salvar"
                        />
                    )}
                    <p className="mt-2 text-sm text-green-600 dark:text-green-500"> {this.state.msg}</p>
                </form>
            </>
        );
    }
}

export default withRouter(FormFiles);
