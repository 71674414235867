import { Component } from "react";

export class FooterCustom extends Component {
    render() {
        return <>

            <footer className="py-12 bg-gray-50 xl:pt-24 dark:bg-gray-800">
                <div className="w-full px-4 mx-auto max-w-8xl">
                    <div className="flex justify-between items-center">
                        <div className="col-span-2">
                            <a href="/" className="flex ms-2 md:me-24">
                                <img src="/logo01.png" className="h-11 me-3" alt="SecLogo" />
                                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">SEC24</span>
                            </a>
                        </div>
                        <div>
                            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400 ml-auto">
                                <li>
                                    <a href="#" className="hover:underline me-4 md:me-6">Sobre</a>
                                </li>
                                <li>
                                    <a href="#" className="hover:underline me-4 md:me-6">Política de Privacidade</a>
                                </li>
                                <li>
                                    <a href="#" className="hover:underline me-4 md:me-6">Licenciamento</a>
                                </li>
                                <li>
                                    <a href="#" className="hover:underline me-4 md:me-6">Contato</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <hr className="my-8 border-gray-200 dark:border-gray-700 lg:my-12" />
                    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400"> Todos os direitos reservados. © 2024 <a href="/" className="hover:underline">Saúde Em Casa 24h</a>.</span>
                </div>
            </footer>

        </>
    }
}