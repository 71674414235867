import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';

interface ComponentProps {
  label: string;
  value: string | null;
  name: string;
  onChange?: (date: Date | null, name: string) => void;
}

export const DatePickerCustom: React.FC<ComponentProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value ? new Date(event.target.value) : null;
    if (props.onChange) {
      props.onChange(date, props.name);
    }
  };

  return (
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {props.label}
      </label>
      <div className="relative">
        <input
          type="date"
          value={props.value || ''}
          name={props.name}
          onChange={handleChange}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
    </div>
  );
};
