import React from 'react';
import api_app from '../apis/api_app';
import SpinnerCustom from './SpinnerCustom';
import { pessoaModel } from '../resource/request';
import moment from 'moment';
import { Tabs } from "flowbite-react";
import { HiClipboardList, HiUserCircle } from "react-icons/hi";
import { Accordion, Timeline } from "flowbite-react";

interface PatientCardProps {
    id: number;
}

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const PatientCard: React.FC<PatientCardProps> = ({ id }) => {
    const [patientData, setPatientData] = React.useState<pessoaModel | null>(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (id !== undefined) {
            setLoading(true);
            api_app.getPessoa(id)
                .then((response) => {
                    setPatientData(response.data);
                })
                .catch((error) => {
                    console.error('Erro ao buscar detalhes do paciente:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [id]);

    if (loading) {
        return (
            <div className="flex justify-center items-center dark:bg-gray-900 p-4">
                <SpinnerCustom />
            </div>
        );
    }



    if (!patientData) {
        return (
            <div className="flex justify-center items-center dark:bg-gray-900 p-4">
                <p className="text-gray-500">Não possui consulta registrada.</p>
            </div>
        );
    }

    return (
        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-4 max-w-sm mx-auto">
            <div className="flex items-center mb-4">
                <img
                    src={
                        patientData.pessoa_documento?.find(x => x.tipo_documento_id === 7)
                            ? REACT_APP_BASE_URL + "/pessoa-documento/documento/" + patientData.pessoa_documento.find(x => x.tipo_documento_id === 7)?.id
                            : '/path/to/default-image.jpg'
                    }
                    alt={patientData.nome}
                    className="w-20 h-20 rounded-full object-cover mr-4"
                />
                <h2 className="text-xl font-semibold">{patientData?.nome || ''}</h2>
            </div>
            <div className="space-y-2">
                <p><strong>CPF: </strong> {patientData?.cpf_cnpj || ''}</p>
                <p><strong>Data de Nascimento: </strong>{moment(patientData?.dat_nascimento_fundacao?.toString() || new Date()?.toString())?.format('DD/MM/YYYY')}</p>
                <p><strong>Email: </strong> {patientData?.pessoa_email?.find((x) => x.flg_principal === 'S')?.email || ''}</p>
                <p><strong>Telefone: </strong> {patientData?.pessoa_telefone?.find((x) => x.flg_principal === 'S')?.telefone || ''}</p>
            </div>
        </div>

        // <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md p-6 max-w-xl mx-auto">
        //     <div className="flex justify-between items-center mb-4">
        //         <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        //             {patientData.nome}
        //         </h5>
        //     </div>

        //     <Tabs aria-label="Tabs with icons">
        //         <Tabs.Item active title="Detalhes do Paciente" icon={HiUserCircle}>
        //             <div className="flex flex-col md:flex-row bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow">
        //                 <img
        //                     className="object-cover w-full h-96 md:h-auto md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg"
        //                     src={
        //                         patientData.pessoa_documento?.find(x => x.tipo_documento_id === 7)
        //                             ? `${REACT_APP_BASE_URL}/pessoa-documento/documento/${patientData.pessoa_documento.find(x => x.tipo_documento_id === 7)?.id}`
        //                             : '/path/to/default-image.jpg'
        //                     }
        //                     alt="Sem Foto"
        //                 />
        //                 <div className="flex flex-col justify-between p-4 leading-normal">
        //                     <p className="dark:text-white"><strong>E-Mail:</strong> {patientData.pessoa_email?.find(x => x.flg_principal === 'S')?.email || ''}</p>
        //                     <p className="dark:text-white"><strong>Telefone:</strong> {patientData.pessoa_telefone?.find(x => x.flg_principal === 'S')?.telefone || ''}</p>
        //                     <p className="dark:text-white"><strong>Data de Nascimento:</strong> {moment(patientData.dat_nascimento_fundacao?.toString() || new Date().toString()).format('DD/MM/YYYY')}</p>
        //                     <p className="dark:text-white"><strong>Estado Civil:</strong> {patientData.pessoa_fisica?.find((x) => true)?.estado_civil?.estado_civil || ''}</p>
        //                     <p className="dark:text-white"><strong>Etnia:</strong> {patientData.pessoa_fisica?.find((x) => true)?.raca_etnia?.raca_etnia || ''}</p>
        //                     <p className="dark:text-white"><strong>Gênero:</strong> {patientData.pessoa_fisica?.find((x) => true)?.tipo_genero?.tipo_genero || ''}</p>
        //                     <p className="dark:text-white"><strong>Pronome Gênero:</strong> {patientData.pessoa_fisica?.find((x) => true)?.tipo_genero_pronome?.tipo_genero_pronome || ''}</p>
        //                     <p className="dark:text-white"><strong>Identidade De Gênero:</strong> {patientData.pessoa_fisica?.find((x) => true)?.tipo_identidade_genero?.tipo_identidade_genero || ''}</p>
        //                 </div>
        //             </div>
        //         </Tabs.Item>
        //         <Tabs.Item title="Consultas" icon={HiClipboardList}>
        //             <Accordion>
        //                 <Accordion.Panel>
        //                     <Accordion.Title className="bg-gray-200 p-4 rounded-lg shadow-md">
        //                         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        //                             <p style={{ marginRight: '10px' }}>
        //                                 Dia: Hora:
        //                             </p>
        //                             <p>
        //                                 Médico:
        //                             </p>
        //                         </div>
        //                     </Accordion.Title>
        //                     <Accordion.Content className="p-4">
        //                         <Timeline className="mt-4">
        //                             <Timeline.Item>
        //                                 <Timeline.Content>
        //                                     <Timeline.Body>
        //                                         <div className="ml-2">
        //                                             Prontuario
        //                                         </div>
        //                                     </Timeline.Body>
        //                                 </Timeline.Content>
        //                             </Timeline.Item>
        //                         </Timeline>
        //                     </Accordion.Content>
        //                 </Accordion.Panel>
        //             </Accordion>
        //         </Tabs.Item>
        //     </Tabs>
        // </div>
    );
};

export default PatientCard;
