import {Component, ReactNode, useState} from "react";
import {Menu} from "../Menu";
import {Especialidades} from "./especialidades/ListEspecialidades";
import FormProfissional from "./FormProfissional";
import {useKeycloak} from "@react-keycloak/web";
import {useDispatch} from "react-redux";
import {store} from "../../../../redux/store";
import api_app from "../../../../apis/api_app";
import {createUser} from "../../../../redux/user/slice";

export function DadosProfissionais() {

    return <div>

        <Menu/>
        <div>
            <Especialidades/>
        </div>
        <div>
            <FormProfissional/>
        </div>
    </div>


}