import React, {Component} from "react";
import SelectCustom, {Option} from "../../../components/SelectCustom";
import CheckboxCustum from "../../../components/CheckboxCustum";
import {ButtonSaveCustom} from "../../../components/ButtonSaveCustom";
import {AboutCustumPage} from "../../../components/AboutCustom";
import {ButtonReturnCustom} from "../../../components/ButtonReturnCustom";
import {TittleCustom} from "../../../components/TittleCustom";
import {agendaModel, FilterDto, pessoa_fisicaModel, pessoaModel} from "../../../resource/request";
import api_app from "../../../apis/api_app";
import withRouter from "../../../helpers/withRouter";
import SpinnerCustom from "../../../components/SpinnerCustom";
import moment from "moment";
import {store} from "../../../redux/store";
import 'moment/locale/pt';  // Import the Portuguese locale
moment.locale('pt');

interface State {
    pessoa: pessoaModel;
    agenda: agendaModel[];
    filter: FilterDto;
    dataInicial: string;
    dataFinal: string;
    horaInicio: string;
    horaFinal: string;
    pessoalogada: pessoaModel,
    especialidades: Option[];
    data: Option[];
    hora: Option[];
    loading: boolean;
    selectedData: string;
    selectedHora: string;
    selectedEspecialidade: string;
    confirmMessage: string;
}

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

class AgendamentoConsulta extends Component<any, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            pessoa: {} as pessoaModel,
            pessoalogada: store.getState().user.user,
            agenda: [] as agendaModel[],
            filter: {},
            dataInicial: '',
            dataFinal: '',
            horaInicio: '',
            horaFinal: '',
            especialidades: [],
            loading: true,
            data: [{key: "0", value: "Selecione a especialidade...", disabled: true}],
            hora: [{key: "0", value: "Selecione a especialidade...", disabled: true}],
            selectedData: '',
            selectedHora: '',
            selectedEspecialidade: '',
            confirmMessage: ''
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;

        this.setState((prevState) => {
            const updatedState: Partial<State> = {
                ...prevState,
                [name]: value
            };
            if (name === 'selectedData') {
                const selectedDate = parseInt(value);
                const selectedAgenda = prevState.agenda.find((x) => x.id === selectedDate);

                if (selectedAgenda) {
                    const horas = prevState.agenda
                        .filter((d) => d.dia === selectedAgenda.dia)
                        .filter((l) => l.status_agenda_id === 1)
                        .map((esp) => ({
                            key: esp.id?.toString() || '',
                            value: moment(esp.horario).format('HH:mm'),
                            disabled: false
                        })).sort((a, b) => {
                            return moment(a.value, 'HH:mm').diff(moment(b.value, 'HH:mm'));
                        });
                    updatedState.hora = horas;
                    updatedState.selectedData = value;
                }
            }

            return updatedState as State;
        });
    };

    async componentDidMount() {
        try {
            const response = await api_app.getPessoa(this.props.router.params.id);
            const responseAgenda = await api_app.getAgendaProfissional(this.props.router.params.id);
            const pessoa = response.data as pessoaModel;
            const agenda = responseAgenda.data as agendaModel[];

            const especialidades = pessoa.pessoa_especialidade.map((esp) => ({
                key: esp.especialidade_id?.toString(),
                value: esp?.especialidade?.especialidade?.toString() || '',
                disabled: false
            }));

            const datas = agenda.filter(
                (d) => new Date(d.dia).getTime() >= new Date().getTime()
            )
                .filter((l) => l.status_agenda_id === 1)
                .map((esp) => (
                    {
                        key: esp.id?.toString() || '',
                        value: moment(esp.dia.toString()).format('LL'),
                        disabled: false
                    }
                ));
            const data = Array.from(new Map(datas.map(item => [item.value, item])).values());
            this.setState({pessoa, especialidades, loading: false, data, agenda});

        } catch (error) {
            console.error(error);
            this.setState({loading: false});
        }
    }

    handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const {selectedData, selectedHora, pessoa, selectedEspecialidade, pessoalogada} = this.state;
        if (!selectedData || !selectedHora) {
            this.setState({confirmMessage: 'Por favor, selecione data e hora.'});
            return;
        }

        const pacienteId = pessoalogada.id || '';
        const selectedAgenda = pacienteId;

        if (!selectedAgenda) {
            this.setState({confirmMessage: 'ID do paciente não encontrado.'});
            return;
        }

        try {
            await api_app.patchAgenda(selectedHora, {
                paciente_id: pacienteId,
                id: parseInt(selectedHora),
                especialidade_id: parseInt(selectedEspecialidade),
                status_agenda_id: 3
            });
            this.setState({confirmMessage: 'Consulta agendada com sucesso!'});
            this.props.router.navigate('/agendamento-de-consulta');
        } catch (error) {
            console.error('Erro ao agendar consulta:', error);
            this.setState({confirmMessage: 'Erro ao agendar consulta.'});
        }
    }

    render() {
        if (this.state.loading) {
            return <div className="flex justify-center items-center h-screen">
                <SpinnerCustom/>
            </div>
        }
        return (
            <>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Agendamento de Consulta"
                    />
                    <ButtonReturnCustom
                        link="/agendamento-de-consulta"
                    />
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div className="flex flex-row justify-between mb-6 space-x-6">
                        <div>
                            <AboutCustumPage
                                label={this.state.pessoa.nome || ""}
                                name={this.state.pessoa.pessoa_profissional?.find(() => true)?.resumo_curriculo || ""}
                                src={
                                    this.state.pessoa.pessoa_documento?.find(x => x.tipo_documento_id === 7) ?
                                        REACT_APP_BASE_URL + "/pessoa-documento/documento/" + this.state.pessoa.pessoa_documento.find(x => x.tipo_documento_id === 7)?.id
                                        : ''}
                                pessoaprofission={this.state.pessoa.pessoa_fisica?.find(() => true) || {} as pessoa_fisicaModel}
                            />
                        </div>
                        <div className="flex-grow">
                            <div className="mb-6">
                                <SelectCustom
                                    label="Especialidade:"
                                    name="selectedEspecialidade"
                                    defaultValue="Selecione"
                                    options={this.state.especialidades}
                                    onChange={this.handleChange}
                                    required
                                />
                            </div>
                            <div className="grid gap-6 mb-6 md:grid-cols-2">
                                <SelectCustom
                                    label="Data:"
                                    name="selectedData"
                                    defaultValue="Selecione"
                                    options={this.state.data}
                                    onChange={this.handleChange}
                                    required
                                />
                                <SelectCustom
                                    label="Hora:"
                                    name="selectedHora"
                                    defaultValue="Selecione"
                                    options={this.state.hora}
                                    onChange={this.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-6">
                                <CheckboxCustum
                                    label="Declaro estar ciente das condições listadas nos"
                                    name=""
                                />
                            </div>
                            <div>
                                <ButtonSaveCustom
                                    name="Confirmar"
                                    type="submit"
                                />
                            </div>
                            {this.state.confirmMessage && (
                                <div className="mt-4">
                                    <p className="mt-2 text-sm text-green-600 dark:text-green-500">{this.state.confirmMessage}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </>
        );
    }
}

export default withRouter(AgendamentoConsulta);
