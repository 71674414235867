import React, { useState } from 'react';

export interface Option {
    key: string;
    value: string;
    disabled: boolean;
    required?: boolean;
}

interface Props {
    label: string;
    name: string;
    defaultValue: string;
    options: Option[];
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    required?: boolean;
}

const SelectCustom: React.FC<Props> = ({ label, name, defaultValue,  options, onChange, required }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <div>
            <label
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                htmlFor={name}
                aria-placeholder='Selecione'
            >{label}
            </label>

            <select
                required={required}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id={name}
                name={name}
                onChange={handleChange}
            >
                <option value="" >
                    Selecione
                </option>
                {options.map(option => (
                    <option
                        key={option.key}
                        selected={option.key === selectedValue}
                        value={option.key}
                        disabled={option.disabled}>
                        {option.value}
                       
                    </option>
                ))} 
            </select>
        </div>
    );
};

export default SelectCustom;