import { useKeycloak } from "@react-keycloak/web";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { pessoaModel } from "../resource/request";
import   api_app from "../apis/api_app";
import { useSelector } from "react-redux";

const Nav: React.FC = () => {
    const { keycloak } = useKeycloak();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [linklToFile, setLinklToFile] = useState<string | null>(null);
    const pessoa = useSelector((state: any) => state.user?.user as pessoaModel);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownVisible(false);
        }
    };


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (pessoa) {
            const documentos = pessoa.pessoa_documento?.filter(x => x.tipo_documento_id === 7);
            const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
            if (documentos?.length) {
                const image = documentos.length > 0 ? documentos[0] : null;
                setLinklToFile(REACT_APP_BASE_URL + "/pessoa-documento/documento/" + image?.id);
            }
        }
    }, [pessoa]);

    return (
        <div className="flex items-center">
            {keycloak.authenticated && (
                <div className="relative flex items-center ms-3" ref={dropdownRef}>
                    <div>
                        <button
                            type="button"
                            className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                            aria-expanded={dropdownVisible}
                            onClick={toggleDropdown}
                        >
                            <img
                                className="w-8 h-8 rounded-full"
                                src={linklToFile || '/UserLogo.jpeg'}
                                alt="user photo"
                            />
                        </button>
                    </div>
                    {dropdownVisible && (
                        <div
                            className="absolute right-0 top-full mt-2 mr-4 z-50 w-72 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                            id="dropdown-user"
                        >
                            <div className="px-4 py-3">
                                <p className="text-sm text-gray-900 dark:text-white truncate">
                                    {keycloak?.idTokenParsed?.name}
                                </p>
                                <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">
                                    {keycloak?.idTokenParsed?.email}
                                </p>
                            </div>
                            <ul>
                                <li>
                                    <Link
                                        to="/perfil"
                                        className="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                    >
                                        Meus Dados
                                    </Link>
                                </li>
                                <li className="border-t border-gray-100 dark:border-gray-600">
                                    <Link
                                        to="/"
                                        onClick={() => keycloak.logout()}
                                        className="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                    >
                                        Sair
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Nav;
