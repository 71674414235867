import { Link } from "react-router-dom";

export interface PropertiesCustom {
    name: string,
}

export const TittleCustom = (props: PropertiesCustom) => {

    return (
        <>
            <div className="flex items-center justify-between p-4 md:p-5  rounded-t dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {props.name}
                </h3>
            </div>   
        </>
    );
}