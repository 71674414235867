import { Component } from "react";
import { Menu } from "../../dadospessoais/meuperfil/Menu";
import { PlanoFinanceiro } from "./planoindividual/ListPlanoIndividual";
import { PlanoProfissional } from "./planoprofissional/ListPlanoProfissional";

export class Planos extends Component {
    render() {
        return <div>           
            <div>
               <PlanoFinanceiro />
            </div>
            <div>
               <PlanoProfissional />
            </div>
        </div>
    }
}