import { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor } from '@ckeditor/ckeditor5-core';
import api_app from '../apis/api_app';

interface CKEditorWrapperProps {
  id: string;
}

const CKEditorWrapper: React.FC<CKEditorWrapperProps> = ({ id }) => {
  const [editorData, setEditorData] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [buttonVisible, setButtonVisible] = useState<boolean>(true);


  const handleEditorChange = (event: any, editor: Editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const handleEditorReady = (editor: Editor) => {
    const editableElement = editor.ui.view.editable.element;
    if (editableElement) {
      editableElement.style.height = '500px';
    }

    editor.model.document.on('change:data', () => {
      if (editableElement) {
        editableElement.style.height = '500px';
      }
    });

    editor.editing.view.document.on('click', () => {
      if (editableElement) {
        editableElement.style.height = '500px';
      }
    });
  };

  const stripHtml = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  const handleSubmit = async () => {
    try {
      const dataToSend = {
        agenda_id: parseInt(id),
        parecer: editorData,
      };

      const response = await api_app.postProntuario(dataToSend);

      setMessage('Prontuário salvo com sucesso!');
      console.log('Prontuario salvo com sucesso', response.data);
      setButtonVisible(false);

    } catch (error) {
      setMessage('Erro ao salvar o Prontuário. Tente novamente.');
      console.error('Erro ao salvar o Prontuario', error);
    }
  };

  return (
    <div>
      <div className='mb-4'>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: {
              items: [
                'bold','|', 'link',
              ],
            },
            language: 'pt-br',
            image: {
              toolbar: [
                'imageTextAlternative', 'imageStyle:full', 'imageStyle:side'
              ]
            },
            licenseKey: '',
          }}
          onChange={handleEditorChange}
          onReady={handleEditorReady}
        />
      </div>
      <div style={{ textAlign: 'right' }}>
        {buttonVisible && (
          <button
            className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 border border-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 text-right"
            style={{ backgroundColor: '#a7eb51' }}
            onClick={handleSubmit}>
            Salvar
          </button>
        )}
        <div className="mt-4">
          {message && (
            <p className={`mt-2 text-sm ${message.includes('Erro') ? 'text-red-600 dark:text-red-500' : 'text-green-600 dark:text-green-500'}`}>
              {message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CKEditorWrapper;
