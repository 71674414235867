import React from 'react';

interface InputProps {
  label: string;
  name: string;
  placeholder: string;
  type: string;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: string;
}

const InputDisabled: React.FC<InputProps> = (props) => {
  return (
    <div>
      <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-gray-200" htmlFor={props.name}>
        {props.label} {props.errors && <span className="text-red-500">({props.errors})</span>}
      </label>
      <input
        name={props.name}
        className="mb-5 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder={props.placeholder}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        disabled
      />
    </div>
  );
};

export default InputDisabled;
