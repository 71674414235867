import React from "react";

export interface PropertiesCustom {
    name: string;
    label: string;
}

const CheckboxCustom: React.FC<PropertiesCustom> = (props) => {
    return (
        <>
            <input
                name={props.name}
                id={props.name}
                type="checkbox"
                value=""
              required
                className="w-4 h-4 text-blue-900 bg-gray-100 border-gray-300 rounded focus:ring-blue-800 dark:focus:ring-blue-900 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                htmlFor={props.name}
            >
                {props.label}{" "}
                <a
                    href="/CONDICOES_GERAIS_DE_USO_PACIENTE (2).pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-900"
                >
                    Termos de uso da Plataforma
                </a>
            </label>
        </>
    );
};

export default CheckboxCustom;
