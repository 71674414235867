import React, { Component } from "react";
import { TittleCustom } from "../../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../../components/InputCustom";
import { pessoa_enderecoModel } from "../../../../../resource/request";
import { store } from "../../../../../redux/store";
import withRouter from "../../../../../helpers/withRouter";
import api_app from "../../../../../apis/api_app";
import { ToggleCustom } from "../../../../../components/ToggleCustom";
import SpinnerCustom from "../../../../../components/SpinnerCustom";

interface State {
    endereco: pessoa_enderecoModel,
    enderecoPrincipal: pessoa_enderecoModel,
    msg: string,
    isLoading: boolean;
    isSubmitted: boolean;
}

export default withRouter(class EditEndereco extends Component<any> {

    state: State = {
        endereco: {} as pessoa_enderecoModel,
        enderecoPrincipal: {} as pessoa_enderecoModel,
        msg: '',
        isLoading: true,
        isSubmitted: false,
    }

    componentDidMount() {
        const id = this.props.router.params.id
        store.getState().user.user.pessoa_endereco.map(enderecoa => {
            if (enderecoa.id.toString() === id.toString()) {
                this.setState({ endereco: enderecoa, isLoading: false });
            }
            if (enderecoa.flg_principal === "S" && enderecoa.id.toString() !== id.toString()) {
                this.setState({enderecoPrincipal: enderecoa });
            }
        })
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        this.setState({
            endereco: {
                ...this.state.endereco,
                [name]: value
            }
        });
    };

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        api_app.atualizarEndereco(this.state.endereco.id, this.state.endereco).then(x => {
            const msg = "Seu endereço foi atualizado com com suscesso!";
            this.setState({ msg, isSubmitted: true });
            this.props.router.updateStore();
        });
    };

    render() {
        if (this.state.isLoading) {
            return <div><SpinnerCustom /></div>;
        }
        return <>
            <form onSubmit={this.handleSubmit}>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Alterar Endereço"
                    />
                    <ButtonReturnCustom
                        link="/perfil"
                    />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <InputCustom
                        label="CEP:"
                        value={this.state?.endereco?.cep || ''}
                        type="text"
                        mask="99999-999"
                        name="cep"
                        placeholder="CEP"
                        onChange={this.handleChange}
                    />
                    <InputCustom
                        label="País:"
                        value={this.state?.endereco?.pais || ''}
                        type="text"
                        name="pais"
                        placeholder="País"
                        onChange={this.handleChange}
                    />
                    <InputCustom
                        label="Estado:"
                        value={this.state?.endereco?.estado || ''}
                        type="text"
                        name="estado"
                        placeholder="Estado"
                        onChange={this.handleChange}
                    />
                    <InputCustom
                        label="Cidade:"
                        value={this.state?.endereco?.cidade || ''}
                        type="text"
                        name="cidade"
                        placeholder="Cidade"
                        onChange={this.handleChange}
                    />
                    <InputCustom
                        label="Bairro:"
                        value={this.state?.endereco?.bairro || ''}
                        type="text"
                        name="bairro"
                        placeholder="Bairro"
                        onChange={this.handleChange}
                    />
                    <InputCustom
                        label="Rua:"
                        value={this.state?.endereco?.endereco || ''}
                        type="text"
                        name="endereco"
                        placeholder="Rua"
                        onChange={this.handleChange}
                    />
                    <InputCustom
                        label="Número:"
                        value={this.state?.endereco?.numero || ''}
                        type="number"
                        name="numero"
                        placeholder="Número"
                        onChange={this.handleChange}
                    />
                    <InputCustom
                        label="Complemento:"
                        value={this.state?.endereco?.complemento || ''}
                        type="text"
                        name="complemento"
                        placeholder="Complemento"
                        onChange={this.handleChange}
                    />
                    <InputCustom
                        label="Descrição:"
                        value={this.state?.endereco?.observacao || ''}
                        type="text"
                        name="observacao"
                        placeholder="Descrição"
                        onChange={this.handleChange}
                    />
                    <div className="flex items-center">
                        <ToggleCustom
                            label="Endereço Principal?"
                            value={this.state?.endereco.flg_principal || 'S'}
                            name='flg_principal'
                            onChange={this.handleChange}
                            disabled={this.state?.enderecoPrincipal?.id !== undefined ? true : false}
                            defaultValue={this.state?.endereco.flg_principal || 'N'}
                        />
                    </div>
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    {!this.state.isSubmitted && (
                        <ButtonSaveCustom
                            type="submit"
                            name="Salvar"
                        />
                    )}
                </div>
                <p className="mt-2 text-sm text-green-600 dark:text-green-500">{this.state.msg}</p>
            </form>
        </>
    }
})