import { Component } from "react";
import { Link } from "react-router-dom";
import { createUser } from "../../../redux/user/slice";
import { Dispatch } from "redux";
import api_app from "../../../apis/api_app";
import { hasGroup, keycloak } from "../../../keycloak";

interface State {
    profissional: boolean;
    paciente: boolean;
    desenvolvedor: boolean
}

export class Menu extends Component<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = {
            profissional: hasGroup("/profissional") || false,
            paciente: hasGroup("/paciente") || false,
            desenvolvedor: hasGroup("/desenvolvedor") || false,
        };
    }

    async componentDidMount() {
        try {
            if (keycloak.authenticated) {
               
                    this.setState({
                        profissional: hasGroup("/profissional") || false,
                        paciente: hasGroup("/paciente") || false,
                        desenvolvedor: hasGroup("/desenvolvedor") || false,

                    });
              
            }
        } catch (error) {
            console.error("Failed to fetch user data:", error);
        }
    }

    render() {
        const { profissional, paciente, desenvolvedor } = this.state;

        return (
            <div className="flex rounded-md shadow-sm">
                <Link
                    to="/perfil"
                    className="flex-1 inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-l border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                >
                    Dados Pessoais
                </Link>
                <Link
                    to="/dados-pacientes"
                    className="flex-1 inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-l border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                >
                    Minha saúde
                </Link>
                {profissional && (
                    <Link
                        to="/dados-profissionais"
                        className="flex-1 inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-l border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                    >
                        Dados Profissionais
                    </Link>
                )}
                {desenvolvedor && ( 
                <Link
                    to="/dados-financeiros"
                    className="flex-1 inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-l border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                >
                    Dados Financeiros
                </Link>
                )}
            </div>
        );
    }
}
