import { Component } from "react";
import { TittleCustom } from "../../../../components/TittleCustom";
import { ButtonReturnCustom } from "../../../../components/ButtonReturnCustom";
import { ButtonSaveCustom } from "../../../../components/ButtonSaveCustom";
import InputCustom from "../../../../components/InputCustom";
import SelectCustom, { Option } from "../../../../components/SelectCustom";
import { DatePickerCustom } from "../../../../components/DatePickerCustom";

const consulta_presencial: Option[] = [
    { key: "1", value: "Sim", disabled: false },
    { key: "2", value: "Não", disabled: false }
];

const consulta_online: Option[] = [
    { key: "1", value: "Sim", disabled: false },
    { key: "2", value: "Não", disabled: false }
]

const pronto_atendimento: Option[] = [
    { key: "1", value: "Sim", disabled: false },
    { key: "2", value: "Não", disabled: false }
]


export class EditPlanoIndividual extends Component {
    render() {
        return <>
            <form>
                <div className="flex items-center justify-between rounded-t dark:border-gray-600">
                    <TittleCustom
                        name="Alterar Plano De Saúde Individual"
                    />
                    <ButtonReturnCustom
                        link="/planos"
                    />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <InputCustom
                        label="Tipo De Assinatura:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Tipo De Assinatura"
                    />
                    <InputCustom
                        label="Status De Assinatura:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Status De Assinatura"
                    />
                   <DatePickerCustom
                        label="Próximo Vencimento:"
                        value=""
                        name=""
                        onChange={() => { }}
                    />
                    <InputCustom
                        label="Valor De Recorrência:"
                        value=""
                        type="number"
                        name="name"
                        placeholder="Valor De Recorrência"
                    />
                    <InputCustom
                        label="Tipo De Recorrência:"
                        value=""
                        type="text"
                        name="name"
                        placeholder="Tipo De Recorrência"
                    />
                     <DatePickerCustom
                        label="Data Início Vigência:"
                        value=""
                        name=""
                        onChange={() => { }}
                    />
                    <DatePickerCustom
                        label="Data Fim Vigência:"
                        value=""
                        name=""
                        onChange={() => { }}
                    />
                    <InputCustom
                        label="Carência:"
                        value=""
                        type="number"
                        name="name"
                        placeholder="Dias"
                    />
                    <SelectCustom
                        label="Consulta Presencial:"
                        name="name"
                        defaultValue="Selecione"
                        options={consulta_presencial}
                    />
                    <SelectCustom
                        label="Consulta On-line:"
                        name="name"
                        defaultValue="Selecione"
                        options={consulta_online}
                    />
                </div>
                <div>
                    <SelectCustom
                        label="Pronto Atendimento:"
                        name="name"
                        defaultValue="Selecione"
                        options={pronto_atendimento}
                    />
                </div>
                <div className="flex items-center mt-6 space-x-4 rtl:space-x-reverse">
                    <ButtonSaveCustom
                        type="submit"
                        name="Salvar"
                    />
                </div>
            </form>
        </>
    }
}