import Keycloak from "keycloak-js";
import {jwtDecode} from "jwt-decode";

const KEYCLOAKURLAP = process.env.REACT_APP_KEYCLOAKURLAP;
const KEY_CLOAK_REALM = process.env.REACT_APP_KEY_CLOAK_REALM;
const KEY_CLOAK_CLIENT_ID = process.env.REACT_APP_KEY_CLOAK_CLIENT_ID;
const keycloak = new Keycloak({
    url: KEYCLOAKURLAP,
    realm: KEY_CLOAK_REALM,
    clientId: KEY_CLOAK_CLIENT_ID,
});
const initKeycloak = (onAuthenticatedCallback) => {
    keycloak.init({onLoad: 'login-required'}).then((authenticated) => {
        if (authenticated) {
            onAuthenticatedCallback()

        } else {
            console.warn('Not authenticated!');
        }
    }).catch((error) => {
        console.error('Failed to initialize Keycloak', error);
    });
};

const getIdToken = () => {
    if (keycloak.authenticated) {
        return keycloak.token;
    } else {
        return null;
    }
};

const loadUserInfo = async () => {
    if (keycloak.authenticated) {
        return await keycloak.loadUserInfo();
    } else {
        return null;
    }
};

const hasGroup = (group) => {
    if (keycloak.authenticated) {
        const decodedToken = jwtDecode(keycloak.token);
        console.log(!!decodedToken?.groups?.includes(group));
        return !!decodedToken?.groups?.includes(group);
    }
}

export {keycloak, initKeycloak, getIdToken, loadUserInfo, hasGroup};
